import { useState } from "react";
import { StepsProvider, Steps, useSteps } from "react-step-builder";
import { getRapidQuoteDataAxios, sendDotAxios } from "../api/axiosFunctions";
import { IconContext } from "react-icons";
import { TiTick, TiTimes } from 'react-icons/ti';
import coverWhaleStates from '../data/Updated-Coverage.json';
import cargos from '../data/cargos.json';
import logo from '../images/AFIwhitelogoPDF.png';

const RapidQuote = () => {
  return (
    <StepsProvider>
      <RapidQuoteSteps />
    </StepsProvider>
  );
};

const RapidQuoteSteps = () => {
  const [DOTNumber, setDOTNumber] = useState('');
  const [cargo, setCargo] = useState([]);
  const [state, setState] = useState([]);
  const [generalData, setGeneralData] = useState({
    cargo: {
      cargoClassDesc: "Description",
      id: {
        cargoClassId: 0,
        dotNumber: 0
      }
    },
    operation: {
      id: {
        dotNumber: 0,
        operationClassId: 0
      },
      operationClassDesc: "Description"
    },
    generalData: {
      allowedToOperate: "Y",
      bipdInsuranceOnFile: "750",
      bipdInsuranceRequired: "Y",
      bipdRequiredAmount: "750",
      bondInsuranceOnFile: "0",
      bondInsuranceRequired: "u",
      brokerAuthorityStatus: "N",
      cargoInsuranceOnFile: "0",
      cargoInsuranceRequired: "u",
      carrierOperation: {
        carrierOperationCode: "A",
        carrierOperationDesc: "Description"
      },
      censusTypeId: {
        censusType: "C",
        censusTypeDesc: "CARRIER",
        censusTypeId: 0
      },
      commonAuthorityStatus: "A",
      contractAuthorityStatus: "N",
      crashTotal: 0,
      dbaName: null,
      dotNumber: 0,
      driverInsp: 0,
      driverOosInsp: 0,
      driverOosRate: 0,
      driverOosRateNationalAverage: "5.51",
      ein: 0,
      fatalCrash: 0,
      hazmatInsp: 0,
      hazmatOosInsp: 0,
      hazmatOosRate: 0,
      hazmatOosRateNationalAverage: "4.5",
      injCrash: 0,
      isPassengerCarrier: "N",
      issScore: null,
      legalName: "Name",
      mcs150Outdated: "N",
      oosDate: null,
      oosRateNationalAverageYear: "year",
      phyCity: "City",
      phyCountry: "US",
      phyState: "State",
      phyStreet: "Street",
      phyZipcode: "ZIP",
      reviewDate: null,
      reviewType: null,
      safetyRating: null,
      safetyRatingDate: null,
      safetyReviewDate: null,
      safetyReviewType: null,
      snapshotDate: null,
      statusCode: "A",
      totalDrivers: 0,
      totalPowerUnits: 0,
      towawayCrash: 0,
      vehicleInsp: 0,
      vehicleOosInsp: 0,
      vehicleOosRate: 0,
      vehicleOosRateNationalAverage: "0"
    }
  });
  const [companyData, setCompanyData] = useState({
    dbaName: "Name",
    emailAddress: "Email",
    legalName: "Name",
    mailingCity: "City",
    mailingCountry: "US",
    mailingState: "State",
    mailingStreet: "Street",
    mailingZip: "ZIP",
    phyCity: "City",
    phyCountry: "US",
    phyState: "State",
    phyStreet: "Street",
    phyZip: "ZIP",
    powerUnits: "Units",
    telephone: "Telephone",
    totalDrivers: "Drivers",
  });
  const { next, prev, current } = useSteps();

  const handleDOTNumber = (event) => {
    let newValue = event.target.value;
    setDOTNumber(newValue);
  };

  const handleGetDataSubmit = async (event) => {
    event.preventDefault();
    const response = await sendDotAxios(DOTNumber);
    const generalDataResponse = await getRapidQuoteDataAxios(DOTNumber);
    if (response.status === 404 || generalDataResponse === 'there was an error') {
      alert('DOT Number not found');
    } else {
      const cargoList = generalDataResponse.cargo.map(cargoElement => {
        return cargos.filter(cargo => {
          return cargo.Cargo === cargoElement.cargoClassDesc;
        })[0];
      });
      setCargo(cargoList);
      const stateProgressive = coverWhaleStates[0].Progressive.filter(state => {
        return state.State === generalDataResponse.generalData.phyState
      });
      const stateCoverWhale = coverWhaleStates[1].CoverWhale.filter(state => {
        return state.State === generalDataResponse.generalData.phyState
      });
      setState([stateCoverWhale[0], stateProgressive[0]]);
      setGeneralData(generalDataResponse);
      setCompanyData(response.data.generalDot);
      next();
    };
  };

  return (
    <>
      <div className="flex justify-between bg-[#0f2060] p-4">
        <img src={logo} className="w-36" alt="logo" />
        {current === 2 &&
          <button
            type="button"
            onClick={prev}
            className="bg-red-700 text-white font-bold rounded text-xl w-fit py-2 px-6 ml-auto mr-8"
          >
            Restart
          </button>
        }
      </div>
      <div className='bg-[#f3f3f9] flex justify-center min-h-screen'>
        <Steps>
          <form onSubmit={handleGetDataSubmit} className="w-1/2 mt-20 p-10 bg-white rounded h-fit">
            <label className="font-bold text-3xl">DOT Number
              <input name="dot_number" type="number" onChange={handleDOTNumber} value={DOTNumber} />
            </label>
            <div className="flex justify-center mt-8">
              <button type="submit" className="bg-red-700 text-white w-1/2 rounded-3xl py-2 px-10">GET Company Data</button>
            </div>
          </form>
          <div className="w-5/6 bg-white mt-5 rounded h-fit pb-6 flex flex-col">
            <h2 className="text-3xl mt-2">{generalData.generalData.legalName}</h2>
            <h2 className="text-3xl">DOT #{DOTNumber}</h2>
            <div className="flex flex-col">
              <div className="px-4 py-2">
                <h3 className="pb-2 font-bold text-left text-xl">State</h3>
                <div className="p-2 text-left flex bg-[#f3f3f9] rounded items-center">
                  <p className="text-7xl w-fit p-2 text-green-700">{generalData.generalData.phyState}</p>
                  <div className="overflow-x-auto w-fit ml-20 scrollbar">
                    <table className="whitespace-nowrap">
                      <thead>
                        <tr>
                          <th scope="col" />
                          <th scope="col" className="px-3 text-left text-sm font-semibold">
                            Auto Liability
                          </th>
                          <th scope="col" className="px-3 text-center text-sm font-semibold sm:pl-3">
                            Physical Damage
                          </th>
                          <th scope="col" className="px-3 text-center text-sm font-semibold">
                            Motor Truck Damage
                          </th>
                          <th scope="col" className="px-3 text-center text-sm font-semibold">
                            Trucker General Liability
                          </th>
                          <th scope="col" className="px-3 text-center text-sm font-semibold">
                            Non trucking Liability
                          </th>
                          <th scope="col" className="px-3 text-center text-sm font-semibold">
                            Hot Shots
                          </th>
                          <th scope="col" className="px-3 text-center text-sm font-semibold">
                            UIIA
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <th scope="col" className="p-3 border-r-2 border-black text-right text-sm font-bold">
                          Coverwhale
                        </th>
                        <th scope="col" className="p-3 text-center text-sm font-semibold sm:pl-3">
                          {state?.[0]?.["Auto Liability"] === 'NO' ?
                            <IconContext.Provider value={{size: "30", color:'red' }}>
                              <TiTimes />
                            </IconContext.Provider>
                          :
                          
                            <IconContext.Provider value={{size: "30", color:'green' }}>
                              <TiTick />
                            </IconContext.Provider>
                          }
                        </th>
                        <th scope="col" className="p-3 text-center text-sm font-semibold sm:pl-3">
                          {state?.[0]?.["Physical Damage"] === 'NO' ?
                            <IconContext.Provider value={{size: "30", color:'red' }}>
                              <TiTimes />
                            </IconContext.Provider>
                          :
                          
                            <IconContext.Provider value={{size: "30", color:'green' }}>
                              <TiTick />
                            </IconContext.Provider>
                          }
                        </th>
                        <th scope="col" className="p-3 text-center text-sm font-semibold">
                          {state?.[0]?.["Motor Truck Cargo"] === 'NO' ?
                            <IconContext.Provider value={{size: "30", color:'red' }}>
                              <TiTimes />
                            </IconContext.Provider>
                          :
                          
                            <IconContext.Provider value={{size: "30", color:'green' }}>
                              <TiTick />
                            </IconContext.Provider>
                          }
                        </th>
                        <th scope="col" className="p-3 text-center text-sm font-semibold">
                          {state?.[0]?.["Truckers General Liability"] === 'NO' ?
                            <IconContext.Provider value={{size: "30", color:'red' }}>
                              <TiTimes />
                            </IconContext.Provider>
                          :
                          
                            <IconContext.Provider value={{size: "30", color:'green' }}>
                              <TiTick />
                            </IconContext.Provider>
                          }
                        </th>
                        <th scope="col" className="p-3 text-center text-sm font-semibold">
                          {state?.[0]?.["Non Trucking Liability"] === 'NO' ?
                            <IconContext.Provider value={{size: "30", color:'red' }}>
                              <TiTimes />
                            </IconContext.Provider>
                          :
                          
                            <IconContext.Provider value={{size: "30", color:'green' }}>
                              <TiTick />
                            </IconContext.Provider>
                          }
                        </th>
                        <th scope="col" className="p-3 text-center text-sm font-semibold">
                          {state?.[0]?.["Hot Shots"] === 'NO' ?
                            <IconContext.Provider value={{size: "30", color:'red' }}>
                              <TiTimes />
                            </IconContext.Provider>
                          :
                          
                            <IconContext.Provider value={{size: "30", color:'green' }}>
                              <TiTick />
                            </IconContext.Provider>
                          }
                        </th>
                        <th scope="col" className="p-3 text-center text-sm font-semibold">
                          {state?.[0]?.UIIA === 'NO' ?
                            <IconContext.Provider value={{size: "30", color:'red' }}>
                              <TiTimes />
                            </IconContext.Provider>
                          :
                          
                            <IconContext.Provider value={{size: "30", color:'green' }}>
                              <TiTick />
                            </IconContext.Provider>
                          }
                        </th>
                      </tr>
                      <tr className="border-t-2 border-black">
                        <th scope="col" className="p-3 border-r-2 border-black text-right text-sm font-bold">
                          Progressive
                        </th>
                        <th scope="col" className="p-3 text-center text-sm font-semibold sm:pl-3">
                          {state?.[1]?.["Auto Liability"] === 'NO' ?
                            <IconContext.Provider value={{size: "30", color:'red' }}>
                              <TiTimes />
                            </IconContext.Provider>
                          :
                          
                            <IconContext.Provider value={{size: "30", color:'green' }}>
                              <TiTick />
                            </IconContext.Provider>
                          }
                        </th>
                        <th scope="col" className="p-3 text-center text-sm font-semibold sm:pl-3">
                          {state?.[1]?.["Physical Damage"] === 'NO' ?
                            <IconContext.Provider value={{size: "30", color:'red' }}>
                              <TiTimes />
                            </IconContext.Provider>
                          :
                          
                            <IconContext.Provider value={{size: "30", color:'green' }}>
                              <TiTick />
                            </IconContext.Provider>
                          }
                        </th>
                        <th scope="col" className="p-3 text-center text-sm font-semibold">
                          {state?.[1]?.["Motor Truck Cargo"] === 'NO' ?
                            <IconContext.Provider value={{size: "30", color:'red' }}>
                              <TiTimes />
                            </IconContext.Provider>
                          :
                          
                            <IconContext.Provider value={{size: "30", color:'green' }}>
                              <TiTick />
                            </IconContext.Provider>
                          }
                        </th>
                        <th scope="col" className="p-3 text-center text-sm font-semibold">
                          {state?.[1]?.["Truckers General Liability"] === 'NO' ?
                            <IconContext.Provider value={{size: "30", color:'red' }}>
                              <TiTimes />
                            </IconContext.Provider>
                          :
                          
                            <IconContext.Provider value={{size: "30", color:'green' }}>
                              <TiTick />
                            </IconContext.Provider>
                          }
                        </th>
                        <th scope="col" className="p-3 text-center text-sm font-semibold">
                          {state?.[1]?.["Non Trucking Liability"] === 'NO' ?
                            <IconContext.Provider value={{size: "30", color:'red' }}>
                              <TiTimes />
                            </IconContext.Provider>
                          :
                          
                            <IconContext.Provider value={{size: "30", color:'green' }}>
                              <TiTick />
                            </IconContext.Provider>
                          }
                        </th>
                        <th scope="col" className="p-3 text-center text-sm font-semibold">
                          {state?.[1]?.["Hot Shots"] === 'NO' ?
                            <IconContext.Provider value={{size: "30", color:'red' }}>
                              <TiTimes />
                            </IconContext.Provider>
                          :
                          
                            <IconContext.Provider value={{size: "30", color:'green' }}>
                              <TiTick />
                            </IconContext.Provider>
                          }
                        </th>
                        <th scope="col" className="p-3 text-center text-sm font-semibold">
                          {state?.[1]?.UIIA === 'NO' ?
                            <IconContext.Provider value={{size: "30", color:'red' }}>
                              <TiTimes />
                            </IconContext.Provider>
                          :
                          
                            <IconContext.Provider value={{size: "30", color:'green' }}>
                              <TiTick />
                            </IconContext.Provider>
                          }
                        </th>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="px-4 py-2 w-full">
              <h3 className="pb-2 font-bold text-left text-xl">Cargo</h3>
              <div className="p-2 text-left flex bg-[#f3f3f9] rounded items-center w-full">
                <div className="overflow-x-auto w-fit scrollbar">
                  <table className="whitespace-nowrap">
                    <thead>
                      <tr>
                        <th scope="col" />
                        {cargo?.map(item => (
                          <th key={item?.Cargo} scope="col" className="px-3 text-center text-sm font-semibold">
                            {item?.Cargo}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="col" className="p-3 border-r-2 border-black text-right text-sm font-bold">
                          Coverwhale
                        </th>
                        {cargo?.map(item => (
                          <th key={item?.Cargo + item?.CoverWhale} scope="col" className="p-3 text-center text-sm font-semibold">
                            {item?.CoverWhale === 'NO' ?
                            <IconContext.Provider value={{size: "30", color:'red' }}>
                              <TiTimes />
                            </IconContext.Provider>
                          :
                          
                            <IconContext.Provider value={{size: "30", color:'green' }}>
                              <TiTick />
                            </IconContext.Provider>
                          }
                          </th>
                        ))}
                      </tr>
                      <tr className="border-t-2 border-black">
                        <th scope="col" className="p-3 border-r-2 border-black text-right text-sm font-bold">
                          Progressive
                        </th>
                        {cargo?.map(item => (
                          <th key={item?.Cargo + item?.Progressive} scope="col" className="p-3 text-center text-sm font-semibold">
                            {item?.Progressive === 'NO' ?
                            <IconContext.Provider value={{size: "30", color:'red' }}>
                              <TiTimes />
                            </IconContext.Provider>
                          :
                          
                            <IconContext.Provider value={{size: "30", color:'green' }}>
                              <TiTick />
                            </IconContext.Provider>
                          }
                          </th>
                        ))}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <h2 className="text-3xl mb-4">Company Details</h2>
            <div className="flex justify-center gap-10 text-left mb-5 bg-white">
              <div className="p-4 bg-[#f3f3f9] w-[45%] font-bold">
                <p>Legal Name: <span className="font-normal">{generalData.generalData.legalName}</span></p>
                <p>DBA Name: <span className="font-normal">{generalData.generalData.dbaName || 'No DBA Name found'}</span></p>
                <p>Address: <span className="font-normal">{generalData.generalData.phyCity + ', ' + generalData.generalData.phyState + ', ' + generalData.generalData.phyZipcode}</span></p>
                <p>Phone: <span className="font-normal">{companyData?.telephone || 'No Phone Found'}</span></p>
                <p>Email: <span className="font-normal">{companyData?.emailAddress || 'No Email Found'}</span></p>
              </div>
              <div className="p-4 bg-[#f3f3f9] w-[45%] font-bold">
                <p>Fleet Size: <span className="font-normal">{generalData.generalData.totalPowerUnits}</span></p>
                <p>Number of Drivers: <span className="font-normal">{generalData.generalData.totalDrivers}</span></p>
                <p>Insurance</p>
                <p>Vehicle Operations: <span className="font-normal">{generalData.generalData.carrierOperation.carrierOperationDesc}</span></p>
                <p>Operating Status: <span className="font-normal">{generalData.operation.operationClassDesc}</span></p>
              </div>
            </div>
          </div>
        </Steps>
      </div>
    </>
  )

};

export default RapidQuote;
