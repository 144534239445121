import { useState } from "react";
import { postDriver } from "../../api/axiosFunctions";
import ImageUploading from 'react-images-uploading';
import { IconContext } from "react-icons";
import { AiOutlineCamera } from 'react-icons/ai';
import usStates from '../../data/us-states-with-quotes.json';

const AddDriversModal = ({setModalDriver, dotNumber}) => {
  const [driverSubmited, setDriverSubmited] = useState(false);
  const [driverNotSubmited, setDriverNotSubmited] = useState(false);
  const [modalLicenseDriver, setModalLicenseDriver] = useState(false);
  const [invalidDriver, setInvalidDriver] = useState(false);
  const [invalidState, setInvalidState] = useState(false);
  const [driver, setDriver] = useState({
    firstName: '',
    lastName: '',
    licenseNumber: '',
    licenseState: '',
    yearsExperience: '',
    dateOfBirth: '',
    dateOfHire: "2023-03-01",
    accidents_total: "0",
    violations_total: "0",
    suspensions_total: "0"
  });
  const [image1, setImage1] = useState([]);
  const [image2, setImage2] = useState([]);

  const onChangeImage1 = (imageList) => {
    setImage1(imageList);
  };

  const onChangeImage2 = (imageList) => {
    setImage2(imageList);
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    if (inputName === "yearsExperience") {
      const yearsWorked = +driver.newValue;
      let dateOfHire = new Date();
      const dd = String(dateOfHire.getDate()).padStart(2, '0');
      const mm = String(dateOfHire.getMonth() + 1).padStart(2, '0');
      const yyyy = dateOfHire.getFullYear() - yearsWorked;
      dateOfHire = yyyy + '-' + mm + '-' + dd;
      setDriver((prevState)=> {
        return({
          ...prevState,
          [inputName]: newValue,
          "dateOfHire": dateOfHire
        });
      });
      return;
    };
    setDriver((prevState)=> {
      return({
        ...prevState,
        [inputName]: newValue,
      });
    });
  };

  const addDriver = async (event) => {
    event.preventDefault();
    setInvalidDriver(false);
    setInvalidState(false);
    if (driver.licenseState === 'selectState') {
      setInvalidState(true);
      return;
    };
    if (Object.values(driver).some((element) => element === '')) {
      setInvalidDriver(true);
      return;
    };
    const formData = new FormData();
    for ( let key in driver ) {
      formData.append(key, driver[key]);
    };
    image1[0] && formData.append("photos", image1[0].file);
    image2[0] && formData.append("photos", image2[0].file);
    formData.append("dotNumber", dotNumber);
    const response = await postDriver(formData);
    if (response.status === 200) {
      setDriverSubmited(true);
      setDriver({
        firstName: '',
        lastName: '',
        licenseNumber: '',
        licenseState: '',
        yearsExperience: '',
        dateOfBirth: '',
        dateOfHire: "2022-03-10",
        accidents_total: "0",
        violations_total: "0",
        suspensions_total: "0"
      });
      setTimeout(() => {
        setDriverSubmited(false);
      }, "9500");
    } else {
      setDriverNotSubmited(true);
    };
  };

  return (
    <div className="flex flex-col items-center pb-5 w-11/12 max-h-[85vh] bg-white rounded lg:w-1/2">
    {modalLicenseDriver &&
      <div className="fixed bg-black w-full h-full z-10 top-0 flex flex-col items-center px-8 pt-28 sm:pt-40 md:pt-44">
        <div className="bg-blue-900 text-white rounded flex justify-between px-4 py-1 lg:text-4xl lg:w-1/2">
          <p className="w-fit">+ Add License</p>
          <button className="w-fit font-bold" onClick={() => setModalLicenseDriver(false)} type="button">X</button>
        </div>
        <div className="bg-white min-h-[60vh] py-4 md:min-h-[30vh] lg:w-1/2">
          <div className="flex flex-col items-center gap-4 md:flex-row">
            <ImageUploading
              multiple
              value={image1}
              onChange={onChangeImage1}
              maxNumber={1}
              dataURLKey="data_url"
            >
              {({
                imageList,
                onImageUpload,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
              }) => (
                <div className="flex flex-col items-center">
                  <b className="text-xl mb-2">Front of Drivers License</b>
                  {imageList[0] ?
                    imageList.map((image, index) => (
                      <div key={index} className="flex flex-col items-center">
                        <img src={image['data_url']} alt="license" className="h-24 w-2/3 mb-4" />
                        <div className="flex justify-center gap-x-4">
                          <button onClick={() => onImageUpdate(index)} className="font-bold bg-blue-700 text-white rounded-lg py-1 lg:text-xl w-1/3">Update</button>
                          <button onClick={() => onImageRemove(index)} className="font-bold bg-red-700 text-white rounded-lg py-1 lg:text-xl w-1/3">Remove</button>
                        </div>
                      </div>
                    ))
                  :
                    <button
                      className="font-bold bg-[#f2f2f2] w-2/3"
                      style={isDragging ? { color: 'red' } : undefined}
                      onClick={onImageUpload}
                      {...dragProps}
                    >
                      <IconContext.Provider value={{size: "100" }}>
                        <div className="pb-4 pt-2">
                          <AiOutlineCamera />
                          <p className="text-xl px-2">Take or Upload Picture</p>
                        </div>
                      </IconContext.Provider>
                    </button>
                  }
                </div>
              )}
            </ImageUploading>
            <ImageUploading
              multiple
              value={image2}
              onChange={onChangeImage2}
              maxNumber={1}
              dataURLKey="data_url"
            >
              {({
                imageList,
                onImageUpload,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
              }) => (
                <div className="flex flex-col items-center">
                  <b className="text-xl mb-2">Back of Drivers License</b>
                  {imageList[0] ?
                    imageList.map((image, index) => (
                      <div key={index} className="flex flex-col items-center">
                        <img src={image['data_url']} alt="license" className="h-24 w-2/3 mb-4" />
                        <div className="flex justify-center gap-x-4">
                          <button onClick={() => onImageUpdate(index)} className="font-bold bg-blue-700 text-white rounded-lg py-1 lg:text-xl w-1/3">Update</button>
                          <button onClick={() => onImageRemove(index)} className="font-bold bg-red-700 text-white rounded-lg py-1 lg:text-xl w-1/3">Remove</button>
                        </div>
                      </div>
                    ))
                  :
                    <button
                      className="font-bold bg-[#f2f2f2] w-2/3"
                      style={isDragging ? { color: 'red' } : undefined}
                      onClick={onImageUpload}
                      {...dragProps}
                    >
                      <IconContext.Provider value={{size: "100" }}>
                        <div className="pb-4 pt-2">
                          <AiOutlineCamera />
                          <p className="text-xl px-2">Take or Upload Picture</p>
                        </div>
                      </IconContext.Provider>
                    </button>
                  }
                </div>
              )}
            </ImageUploading>
          </div>
          <button type="button" onClick={() => setModalLicenseDriver(false)} className="bg-green-700 font-bold text-white rounded-2xl mt-8 p-2 lg:text-xl w-3/4 md:w-1/2">SAVE LICENSE</button>
        </div>
      </div>
    }
      <div className="bg-blue-900 text-white rounded flex justify-between px-4 py-1 lg:text-2xl">
        <p className="w-fit">+ Add Driver</p>
        <button className="w-fit font-bold" onClick={() => setModalDriver('modal_inactive')} type="button">X</button>
      </div>
      <form onSubmit={addDriver} className="flex flex-col px-10 overflow-y-scroll lg:grid lg:grid-cols-2 lg:gap-x-20 lg:px-20 lg:py-5 lg:overflow-hidden">
        <label className="CostumerMultiStepsLabel">
          <h4 className="w-fit text-xl">First Name</h4>
          <input onChange={handleChange} value={driver.firstName} name="firstName" type="text" className="bg-white border-2 border-slate-300 rounded-3xl px-4 py-1" />
        </label>
        <label className="CostumerMultiStepsLabel">
          <h4 className="w-fit text-xl">Last Name</h4>
          <input onChange={handleChange} value={driver.lastName} name="lastName" type="text" className="bg-white border-2 border-slate-300 rounded-3xl px-4 py-1" />
        </label>
        <label className="CostumerMultiStepsLabel">
          <h4 className="w-fit text-xl">License State</h4>
          <select onChange={handleChange} value={driver.licenseState} name="licenseState" className="bg-white border-2 border-slate-300 rounded-3xl px-4 py-1">
            <option value="selectState">Select State</option>
            {usStates.map((state) => (
              <option key={state.id} value={state.value_back}>{state.value}</option>
            ))}
          </select>
          {invalidState && <p className="text-red-700 text-left text-sm pl-4">Please select a State</p>}
        </label>
        <label className="CostumerMultiStepsLabel">
          <h4 className="w-fit text-xl">License Number</h4>
          <input onChange={handleChange} value={driver.licenseNumber} name="licenseNumber" type="number" pattern="\d*" className="bg-white border-2 border-slate-300 rounded-3xl px-4 py-1" />
        </label>
        <label className="CostumerMultiStepsLabel">
          <h4 className="w-fit text-xl">Date of Birth</h4>
          <input onChange={handleChange} value={driver.dateOfBirth} name="dateOfBirth" type="date" className="bg-white border-2 border-slate-300 rounded-3xl px-4 py-1" />
        </label>
        <label className="CostumerMultiStepsLabel">
          <h4 className="w-fit text-xl">Years of Experience</h4>
          <input onChange={handleChange} value={driver.yearsExperience} name="yearsExperience" type="number" pattern="\d*" className="bg-white border-2 border-slate-300 rounded-3xl px-4 py-1" />
        </label>
        <p className="font-bold text-xl lg:text-3xl lg:col-span-2 lg:w-2/3 lg:mx-auto">OR</p>
        <p className=" lg:text-xl lg:col-span-2 lg:w-2/3 lg:mx-auto">Click the button below and upload an image of the driver's license</p>
        <button
          type="button"
          className="bg-blue-700 font-bold text-white rounded-2xl mt-6 p-2 lg:text-xl lg:col-span-2 lg:w-1/2 lg:mx-auto"
          onClick={() => setModalLicenseDriver(true)}
        >
          Upload Drivers License
        </button>
        <button
          type="submit"
          className="bg-green-700 text-white rounded-lg mt-6 p-2 lg:text-3xl lg:col-span-2 lg:mx-auto"
        >
          <b>SUBMIT DRIVER INFORMATION</b>
        </button>
        {driverSubmited && <p className="text-green-700 text-sm font-bold text-center mt-2 lg:text-xl lg:col-span-2">Driver Submited</p>}
        {invalidDriver && <p className="text-red-700 text-sm font-bold text-center mt-2 lg:text-xl lg:col-span-2">Please Fill All Inputs</p>}
        {driverNotSubmited && <p className="text-red-700 text-sm font-bold text-center mt-2 lg:text-xl lg:col-span-2">There was an error submitting the driver</p>}
      </form>
    </div>
  );
};

export default AddDriversModal;
