const Pagination = ({
  itemsPerPage,
  totalItems,
  paginateFront,
  paginateBack,
  currentPage,
}) => (
  <div className='p-2 mt-2'>
    <nav
      className='flex justify-between items-center rounded-md shadow-sm bg-gray-100'
      aria-label='Pagination'
    >
      <button
        onClick={() => {
          paginateBack();
        }}
        type='button'
        disabled={currentPage < 2}
        className='flex items-center w-28 px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50'
      >
        <span>Previous</span>
      </button>
      <div>
        <p className='text-sm text-gray-700'>
          {currentPage * itemsPerPage - 5} - {currentPage * itemsPerPage} of {totalItems} results
        </p>
      </div>
      <button
        onClick={() => {
          paginateFront();
        }}
        type='button'
        disabled={currentPage * itemsPerPage >= totalItems}
        className='flex items-center w-28 px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50'
      >
        <span>Next</span>
      </button>
    </nav>
  </div>
);

export default Pagination;
