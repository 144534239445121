import { useState } from "react";
import Pagination from "../Pagination/Pagination";

const DriversTable = ({drivers}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const driversPerPage = 5;
  const indexOfLastDriver = currentPage * driversPerPage;
  const indexOfFirstDriver = indexOfLastDriver - driversPerPage;
  const currentDrivers = drivers.slice(indexOfFirstDriver, indexOfLastDriver);

  const paginateFront = () => setCurrentPage(currentPage + 1);
  const paginateBack = () => setCurrentPage(currentPage - 1);

  return(
    <div className="flex flex-col xl:w-2/3">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h2 className="text-base lg:text-4xl font-semibold leading-6 text-gray-900">Drivers</h2>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the Drivers in your company
            </p>
          </div>
        </div>
        <div className="mt-4 flow-root">
          <div className="-my-2 overflow-x-auto block no-scrollbar">
            <div className="inline-block min-w-full py-2 align-middle">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-blue-900 text-white border-b sticky top-0 whitespace-nowrap">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-3">
                      Name
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-sm font-semibold">
                      License Number
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-sm font-semibold">
                      License State
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-sm font-semibold">
                      Experience
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-sm font-semibold">
                      Born Date
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-sm font-semibold">
                      image links
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {currentDrivers.map((driver, personIdx) => (
                    <tr key={driver.id + personIdx || driver._id + personIdx} className={personIdx % 2 === 0 ? undefined : 'bg-gray-50'}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-left font-medium text-gray-900 sm:pl-3">
                        {driver.firstName} {driver.lastName}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {driver.licenseNumber}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {driver.licenseState}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {driver.yearsExperience}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {driver.dateOfBirth}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {driver.photos[0]?.urlFile1 && driver.photos[0]?.urlFile1 !== "No file" ?
                          <a href={driver.photos[0]?.urlFile1} target="_blank" rel='noreferrer' className='text-blue-700 underline mr-1'> Link1</a>
                        :
                          'no file 1 '
                        }
                        {driver.photos[0]?.urlFile2 && driver.photos[0]?.urlFile2 !== "No file" ?
                          <a href={driver.photos[0]?.urlFile2} target="_blank" rel='noreferrer' className='text-blue-700 underline mr-1'> Link2</a>
                        :
                          ' no file 2'
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {drivers.length > 5 && 
          <Pagination
            itemsPerPage={driversPerPage}
            totalItems={drivers.length}
            paginateBack={paginateBack}
            paginateFront={paginateFront}
            currentPage={currentPage}
          />
        }
      </div>
    </div>
  );
};

export default DriversTable;
