import { useState } from "react";
import { Importer, ImporterField } from "react-csv-importer";
import { getTotalCSVAxios } from "../api/axiosFunctions";
import logo from '../images/AFI-FullColor.png';
import './CSVGeneratePDF.css';

const CSVGeneratePDF = () => {
  const [links, setLinks] = useState([]);
  const [importedPDF, setImportedPDF] = useState(1);

  const numberWithCommas = (x) => {
    return x.toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <div className="flex flex-col items-center">
      <img src={logo} alt="AFI logo" className="logo z-50 w-1/2 lg:w-1/3 xl:w-1/4 my-4"/>
      <h1 className="text-xl font-bold mb-4">Import CSV file to generate PDF</h1>
  
      <div className="w-2/3">
        <Importer
          dataHandler={async (rows, { startIndex }) => {
            // required, may be called several times
            // receives a list of parsed objects based on defined fields and user column mapping;
            // (if this callback returns a promise, the widget will wait for it before parsing more data)
            if (rows.length !== 1) {
              setImportedPDF(rows.length);
            };
            for (let row of rows) {
              const response = await getTotalCSVAxios(row);
              setLinks(oldlinks => [...oldlinks, response]);
            };
          }}
        >
          <ImporterField name="dotNumber" label="DOT_NUMBER" />
          <ImporterField name="companyName" label="LEGAL_NAME" />
          <ImporterField name="trucks" label="TOTAL_POWER_UNITS" />
          <ImporterField name="drivers" label="TOTAL_DRIVERS" />
          <ImporterField name="businessYears" label="Years In Business" />
          <ImporterField name="coverageStartDate" label="EFFECTIVE_DATE" />
          <ImporterField name="garageState" label="BUS_STATE_CODE" />
          <ImporterField name="garageCity" label="BUS_CITY" />
          <ImporterField name="garageStreet" label="BUS_STREET_PO" />
          <ImporterField name="garageZip" label="BUS_ZIP_CODE" />
        </Importer>
      </div>

      {links.length > 0 &&
        <div className="w-3/4 mt-10">
          <h2 className="text-xl font-bold mb-4">Imported {links.length} from {importedPDF} DOT Numbers</h2>
          <table className="min-w-full h-64 divide-y divide-gray-300">
            <thead className="bg-blue-900 text-white border-b sticky top-0">
              <tr>
                <th scope="col" className="px-3 py-3.5 text-sm font-semibold w-1/4">
                  DOT Number
                </th>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold sm:pl-3 w-1/5">
                  Monthly Premium
                </th>
                <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold">
                  PDF Link
                </th>
              </tr>
            </thead>
            <tbody className="font-bold">
              {links.map((link, index) => (
                <tr key={link + index}>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-1/4">{link?.dotNumber || 'No Number'}</td>
                  <td className="w-1/5 whitespace-nowrap px-3 py-4 text-sm text-gray-500">$ {numberWithCommas(link?.totalpremium / 12) || '0'}</td>
                  {link?.url === 'error' ?
                    <td className="text-red-700 px-3 py-4 w-1/2">There was a problem creating the pdf</td>
                  :
                  <td>
                    <a
                      href={link?.url}
                      rel='noreferrer'
                      target="_blank"
                      className="underline text-blue-600 px-3"
                    >
                      {link?.url}
                    </a>
                  </td>
                  }
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      }

    </div>
  );
}


export default CSVGeneratePDF;
