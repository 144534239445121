import { useState } from "react";
import Pagination from "../Pagination/Pagination";

const VehiclesTable = ({vehicles}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const vehiclePerPage = 5;
  const indexOfLastVehicle = currentPage * vehiclePerPage;
  const indexOfFirstVehicle = indexOfLastVehicle - vehiclePerPage;
  const currentVehicles = vehicles.slice(indexOfFirstVehicle, indexOfLastVehicle);

  const paginateFront = () => setCurrentPage(currentPage + 1);
  const paginateBack = () => setCurrentPage(currentPage - 1);

  return (
    <div className="flex flex-col xl:w-1/3">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h2 className="text-base lg:text-4xl font-semibold leading-6 text-gray-900">Vehicles</h2>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the Vehicles in your company.
            </p>
          </div>
        </div>
        <div className="mt-4 flow-root">
          <div className="-my-2 overflow-x-auto block no-scrollbar">
            <div className="inline-block min-w-full py-2 align-middle">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-blue-900 text-white border-b sticky top-0">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-3">
                      VIN
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold">
                      Year
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold">
                      Model
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold">
                      Value
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {currentVehicles.map((vehicle, personIdx) => (
                    <tr key={vehicle.id + personIdx} className={personIdx % 2 === 0 ? undefined : 'bg-gray-50'}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-left font-medium text-gray-900 sm:pl-3">
                        {vehicle.vin}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {vehicle.year}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {vehicle.model}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        ${vehicle.value}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {vehicles.length > 5 && 
          <Pagination
            itemsPerPage={vehiclePerPage}
            totalItems={vehicles.length}
            paginateBack={paginateBack}
            paginateFront={paginateFront}
            currentPage={currentPage}
          />
        }
      </div>
    </div>
  );
};

export default VehiclesTable;
