import { StepsProvider } from "react-step-builder";
import NewCostumerMultiStepsForm from "../components/CostumerMultiStepsForm/NewCostumerMultiStepsForm";

const CostumerPage = () => (
  <StepsProvider>
    <NewCostumerMultiStepsForm />
  </StepsProvider>
);

export default CostumerPage;
