const Circles = ({circleNumber, current, bgcolor}) => {
  const rows = [];
  const colors = {
    blue: "bg-[#3056d3] border-[#3056d3]",
    lux_purple: "bg-[#542670] border-[#542670]"
  }
  const activeColor = colors[bgcolor] || colors.blue;

  for (let i = 1; i <= circleNumber; i += 1) {
    rows.push(
      <div key={i} className="circleDiv">
        <div className={current > i-1 ? `circleActive ${activeColor}` : "circle"}>{i}</div>
        <span className={`${i === circleNumber && "lastSpanHidden"} ${current > i ? `circleSpanActive ${activeColor}` : "circleSpan"}`} />
      </div>
    );
  }
  return rows;
}

export default Circles;
