import { useParams, useLocation } from 'react-router-dom';
import AllSubmissionsTable from '../components/Tables/AllSubmissionsTable';
import DriversTable from '../components/Tables/DriversTable';
import VehiclesTable from '../components/Tables/VehiclesTable';
import logo from '../images/AFI-FullColor.png';
import truck from '../images/truck2tiny.png';

const SubmissionData = () => {
  let { submission } = useParams();
  const location = useLocation();
  const submissionsData = location.state?.submissions || [];
  const quoteData = location.state?.quoteData || {};
  const vehicles = quoteData.vehicles || [];
  const drivers = quoteData.drivers || [];

  return (
    <div className="flex flex-col items-center font-bold">
      <img src={logo} alt="AFI logo" className="logo z-50 w-1/2 lg:w-1/3 xl:w-1/4 my-4"/>
      <h1 className='lg:text-4xl'>{quoteData.legalName}</h1>
      <div className="w-2/3 flex p-4 shadow-md mt-2">
        <div className="text-left md:w-4/5 flex flex-col justify-center">
          <h3 className="text-2xl font-semibold">
            {quoteData.legalName}
          </h3>
          <p className="text-gray-600 my-4">
            Quote Number: {submission}
          </p>
          <div className='flex flex-col sm:flex-row mb-4 gap-y-4'>
            <p className="text-gray-600">
              Garage Zip: {quoteData.garageZip}
            </p>
            <p className="text-gray-600">
              Garage State: {quoteData.garageState}
            </p>
          </div>
          <div className='flex flex-col sm:flex-row gap-y-4'>
            <p className="text-gray-600">
              DOT Number: {quoteData.dotNumber}
            </p>
            <p className="text-gray-600">
              Fleet size: {quoteData.powerUnits}
            </p>
            <p className="text-gray-600">
              Vehicles: {quoteData.totalDrivers}
            </p>
          </div>
        </div>
        <div className='hidden md:block w-1/3'>
          <img src={truck} alt="truck" className='w-full' />
        </div>
      </div>
      <div className='flex flex-col xl:flex-row justify-center lg:w-11/12 my-4'>
        { vehicles.length > 0 && <VehiclesTable vehicles={vehicles} /> }
        { drivers.length > 0 && <DriversTable drivers={drivers} /> }
      </div>
      { submissionsData.length > 0 && <AllSubmissionsTable submissions={submissionsData} /> }
    </div>
  );
};

export default SubmissionData;
