import { StepsProvider } from "react-step-builder";
import CWNewCostumerMultiStepsForm from "../components/CostumerMultiStepsForm/CWNewCostumerMultiStepsForm";

const CWNewCostumerVersion = () => (
  <StepsProvider>
    <CWNewCostumerMultiStepsForm />
  </StepsProvider>
);

export default CWNewCostumerVersion;
