import { useState } from "react";
import logo from "../images/AFIwhitelogoPDF.png";

const PoxPages = () => {
  const [DOTNumber, setDOTNumber] = useState("");

  const handleDOTNumber = (event) => {
    let newValue = event.target.value;
    setDOTNumber(newValue);
  };

  const handleOpenPagesSubmit = async (event) => {
    event.preventDefault();

    const websites = [
      {
        url: `https://safer.fmcsa.dot.gov/query.asp?searchtype=ANY&query_type=queryCarrierSnapshot&query_param=USDOT&query_string=${DOTNumber}`,
        name: "SAFER"
      },
      {
        url: `https://ai.fmcsa.dot.gov/SMS/Carrier/${DOTNumber}/Overview.aspx`,
        name: "FMCSA"
      }
    ];

    websites.forEach((website) => {
      window.open(website.url, website.name);
    });
  };

  return (
    <>
      <div className="flex justify-between bg-[#0f2060] p-4">
        <img src={logo} className="w-36" alt="logo" />
      </div>
      <div className="bg-[#f3f3f9] flex justify-center min-h-screen">
        <form
          onSubmit={handleOpenPagesSubmit}
          className="w-1/2 mt-20 p-10 bg-white rounded h-fit"
        >
          <label className="font-bold text-3xl">
            DOT Number
            <input
              name="dot_number"
              type="number"
              onChange={handleDOTNumber}
              value={DOTNumber}
            />
          </label>
          <div className="flex justify-center mt-8">
            <button
              type="submit"
              className="bg-red-700 text-white w-1/2 rounded-3xl py-2 px-10"
            >
              GET Company Data
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default PoxPages;
