import { Route, Routes } from 'react-router-dom';
import CostumerPage from './pages/CostumerPage';
import AddDrivers from './pages/AddDrivers';
import Driver from './pages/Driver';
import NewCostumerVersion from './pages/NewCostumerVersion';
import AllSubmissions from './pages/AllSubmissions';
import SubmissionData from './pages/SubmissionData';
import CWNewCostumerVersion from './pages/CWNewCostumerVersion';
import NotFound from './pages/NotFound';
import CSVGeneratePDF from './pages/CSVGeneratePDF';
import RapidQuote from './pages/rapidQuote';
import PoxPages from './pages/PoxPages';
import SecondVersion from './pages/SecondVersion';

function App() {
  return (
    <div className="App flex flex-col items-center">
      <Routes>
        <Route path="/" element={<CostumerPage />} />
        <Route path="/newversion" element={<NewCostumerVersion />} />
        <Route path="/cw" element={<CWNewCostumerVersion />} />
        <Route path="/cw-m" element={<CWNewCostumerVersion />} />
        <Route path="/cw-y" element={<CWNewCostumerVersion />} />
        <Route path="/gsquared" element={<CWNewCostumerVersion />} />
        <Route path="/cff-quotes" element={<CWNewCostumerVersion />} />
        <Route path="/atob" element={<CWNewCostumerVersion />} />
        <Route path="/geotab" element={<CWNewCostumerVersion />} />
        <Route path="/tivly" element={<CWNewCostumerVersion />} />
        <Route path="/secondversion" element={<SecondVersion pathName="/secondversion" />} />
        <Route path="/cff-marketing" element={<SecondVersion pathName="/cff-marketing" />} />
        <Route path="/submissions" element={<AllSubmissions />} />
        <Route path="/submissions/:submission" element={<SubmissionData />} />
        <Route path="/drivers" element={<AddDrivers />} />
        <Route path="/addDriver/:dotNumber" element={<Driver />} />
        <Route path="/generatepdf" element={<CSVGeneratePDF />} />
        <Route path="/rapidquote" element={<RapidQuote />} />
        <Route path="/pox" element={<PoxPages />} />
        <Route path='*' element={ <NotFound /> }/>
      </Routes>
    </div>
  );
}

export default App;
