import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { getAllSubmissionsAxios } from "../api/axiosFunctions";
import Pagination from "../components/Pagination/Pagination";
import logo from '../images/AFI-FullColor.png';

const AllSubmissions = () => {
  const navigate = useNavigate();
  const [dotNumber, setDotNumber] = useState('');
  const [submissions, setSubmissions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const HandleChange = (event) => {
    const newValue = event.target.value;
    setDotNumber(newValue);
  };

  const GetAllSubmissions = async (event) => {
    event.preventDefault();
    const response = await getAllSubmissionsAxios(dotNumber);
    response ? setSubmissions(response.data.history.reverse()) : setSubmissions([]);
  };

  // Get current posts
  const submissionsPerPage = 5;
  const indexOfLastSubmission = currentPage * submissionsPerPage;
  const indexOfFirstSubmission = indexOfLastSubmission - submissionsPerPage;
  const currentSubmissions = submissions.slice(indexOfFirstSubmission, indexOfLastSubmission);

  // Change page
  const paginateFront = () => setCurrentPage(currentPage + 1);
  const paginateBack = () => setCurrentPage(currentPage - 1);

  return (
    <div className="flex flex-col items-center font-bold">
      <img src={logo} alt="AFI logo" className="logo z-50 w-1/2 lg:w-1/3 xl:w-1/4 mt-4"/>
      <form className="w-5/6 flex flex-col items-center" onSubmit={GetAllSubmissions}>
        <h1 className="text-2xl my-8">Your Submissions</h1>
        <label className="w-3/4 lg:w-1/2 flex flex-col lg:flex-row items-center whitespace-nowrap gap-x-2">
          <h2 className="text-lg">Insert your DOT Number: </h2>
          <input
            name="dotNumber"
            type="number"
            pattern="\d*"
            className="border-2 border-slate-700 rounded-2xl placeholder:text-gray-400 max-w-sm lg:max-w-full"
            placeholder="E.g. 123456"
            onChange={HandleChange}
            value={dotNumber}
          />
        </label>
        <button
          type="submit"
          className="bg-blue-700 rounded-2xl text-white text-lg py-2 px-8 mt-4 w-fit"
        >
          GET SUBMISSIONS
        </button>
      </form>
      {submissions.length > 0 ?
        <div className="flex flex-col lg:w-2/3">
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
              <p className="mt-2 text-sm text-gray-700">
                A list of all the submission quotes.
              </p>
            </div>
            <div className="mt-4 flow-root">
              <div className="-my-2 overflow-x-auto block no-scrollbar">
                <div className="inline-block min-w-full py-2 align-middle">
                  <table className="min-w-full h-64 divide-y divide-gray-300">
                    <thead className="bg-blue-900 text-white border-b sticky top-0">
                      <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-3">
                          Submission id
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold">
                          Start Date
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold">
                          Your Quote
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {currentSubmissions.map((submission, personIdx) => (
                        <tr key={submission._id} className={`${personIdx % 2 === 0 ? undefined : 'bg-gray-50'}`}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-left font-medium text-gray-900 sm:pl-3">
                            {submission._id}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {submission.quoteData.coverageStartDate}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <button
                              type="button"
                              className="bg-green-700 rounded text-white lg:text-sm p-2 lg:px-8"
                              onClick={() => navigate(`/submissions/${submission._id}`, {state: {quoteData: submission.quoteData, submissions: submissions}})}
                            >
                              SEE SUBMISSION
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {submissions.length > 5 && 
              <Pagination
                itemsPerPage={submissionsPerPage}
                totalItems={submissions.length}
                paginateBack={paginateBack}
                paginateFront={paginateFront}
                currentPage={currentPage}
              />
            }
          </div>
        </div>
      :
        <p className="text-lg mt-8 lg:mt-36">WAITING FOR YOUR DOT NUMBER ...</p>
      }
    </div>
  );
};

export default AllSubmissions;
