import axios from 'axios';
import { pdf } from '@react-pdf/renderer';
import CompanyPDF2 from '../components/CompanyPDF/CompanyPDF2';

const baseURL = 'https://dot-api.teamafi.com/';

const PDFURL = "https://api.americanfleetinsurance.com/uploadpdf";

const changeDate = (date) => {
  let policyDate = date.split("-");
  policyDate.push(policyDate.shift());
  return policyDate.join("/");
};

let today = new Date();
const dd = String(today.getDate()).padStart(2, '0');
const mm = String(today.getMonth() + 1).padStart(2, '0');
const yyyy = today.getFullYear();
today = mm + '/' + dd + '/' + yyyy ;

const convertToPrice = (x) => {
  return x.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const sendDotAxios = async (dotNumber) => {
  try {
    const response = await axios.post(baseURL + 'website-getdata', {
      dotNumber: dotNumber
    });
    return response;
  } catch (error) {
    return {
      data: {
        error: error
      }
    };
  };
};

export const getRapidQuoteDataAxios = async (dotNumber) => {
  try {
    const generalDataResponse = await axios.get(
      `https://mobile.fmcsa.dot.gov/qc/services/carriers/${dotNumber}?webKey=46683baed566c35088cf6414790d8ae765f3a9db`);
    const operationResponse = await axios.get(
      `https://mobile.fmcsa.dot.gov/qc/services/carriers/${dotNumber}/operation-classification?webKey=46683baed566c35088cf6414790d8ae765f3a9db`);
    const cargoResponse = await axios.get(
      `https://mobile.fmcsa.dot.gov/qc/services/carriers/${dotNumber}/cargo-carried?webKey=46683baed566c35088cf6414790d8ae765f3a9db`);
    if (generalDataResponse.data?.content) {
      return {
        generalData: generalDataResponse.data?.content?.carrier,
        operation: operationResponse.data?.content[0],
        cargo: cargoResponse.data?.content
      };
    }else {
      return 'there was an error'
    };
  } catch (error) {
    alert(error);
    return;
  };
};

export const step1Axios = async (dotNumber, businessYears, coverageStartDate, operation) => {
  try {
    const response = await axios.post(baseURL + 'website-submit', {
      dotNumber: dotNumber,
      yearsInBusiness : businessYears,
      coverageStartDate : coverageStartDate,
      operations: {
        [operation]: 'Y'
      }
    });
    return response;
  } catch (error) {
    console.log(error);
  };
};

export const step2Axios = async (dotNumber, companyName, garageState, garageZip, garageStreet, garageCity, trucks, drivers) => {
  try {
    const response = await axios.post(baseURL + 'website-submit', {
      dotNumber: dotNumber,
      legalName: companyName,
      garageState: garageState,
      garageZip: garageZip,
      garageStreet: garageStreet,
      garageCity: garageCity,
      powerUnits: trucks,
      totalDrivers: drivers
    });
    return response;
  } catch (error) {
    console.log(error);
  };
};

export const step3Axios = async (dotNumber, vehicles) => {
  try {
    const response = await axios.post(baseURL + 'website-submit', {
      dotNumber: dotNumber,
      vehicles: vehicles
    });
    return response;
  } catch (error) {
    console.log(error);
  };
};

export const step4Axios = async (dotNumber, radius, coverage) => {
  try {
    const response = await axios.post(baseURL + 'website-submit', {
      dotNumber: dotNumber,
      radius: radius,
      coverages: coverage
    })
    return response;
  } catch (error) {
    console.log(error);
  };
};

export const step5Axios = async (dotNumber, mail, phone, name) => {
  try {
    const response = await axios.post(baseURL + 'website-submit', {
      dotNumber: dotNumber,
      emailAddress: mail,
      telephone: phone,
      companyRep1: name
    });
    return response;
  } catch (error) {
    console.log(error);
  };
};

export const finalizeQuoteAxios = async (formData) => {
  try {
    const response = await axios.post(baseURL + 'website-submit-finalize', formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    return response;
  } catch (error) {
    console.log(error);
  };
};

export const getTotalAxios = async (insuranceForm, vehicles, drivers, coverage, radius, source, followingHelp) => {
  try {
    const validDate = changeDate(insuranceForm.coverageStartDate);
    const userNameWordsArray = insuranceForm.userName.split(" ");
    const cleanUserNameWordsArray = userNameWordsArray.filter((name) => { return name !== '' });
    for (let i = 0; i < cleanUserNameWordsArray.length; i++) {
      if (cleanUserNameWordsArray[i] !== '') {
        cleanUserNameWordsArray[i] = cleanUserNameWordsArray[i][0].toUpperCase() + cleanUserNameWordsArray[i].substr(1);
      };
    };
    const userName = cleanUserNameWordsArray.join(' ');
    const response = await axios.post(baseURL + 'website-submission', {
      dotNumber: +insuranceForm.dotNumber,
      yearsInBusiness: insuranceForm.businessYears,
      coverageStartDate: validDate,
      legalName: insuranceForm.companyName,
      garageState: insuranceForm.garageState,
      garageZip: insuranceForm.garageZip,
      garageCity: insuranceForm.garageCity,
      garageStreet: insuranceForm.garageStreet,
      powerUnits: insuranceForm.trucks,
      totalDrivers: insuranceForm.drivers,
      vehicles: vehicles,
      drivers: drivers,
      radiusDriving: radius,
      coverage: {
        al: coverage.al ? "Y" : "N",
        ntl: coverage.ntl ? "Y" : "N",
        pd: coverage.pd ? "Y" : "N",
        mtc: coverage.mtc ? "Y" : "N",
        gl: coverage.gl ? "Y" : "N"
      },
      operations: {
        [insuranceForm.opsTruck]: 'Y'
      },
      companyRep1: userName, 
      telephone: insuranceForm.phone,
      emailAddress: insuranceForm.email
    });
    if (response.data.totalpremium) {
      await axios.post(baseURL + 'website-finalize', {
        "dotNumber": insuranceForm.dotNumber,
        "mail": insuranceForm.email,
        "phone": insuranceForm.phone,
        "companyRep1": insuranceForm.userName,
        "totalPremium": response.data.totalpremium
      });
      const pdfUserBlob = await pdf(<CompanyPDF2 pdfData={insuranceForm} coverage={response.data.totalpremium} />).toBlob();
      const companyName = insuranceForm.companyName.replaceAll(' ','_');
      const formData = new FormData();
      formData.append("quote", pdfUserBlob, `${companyName}.pdf`);
      formData.append("mail", insuranceForm.email);
      formData.append("phone", insuranceForm.phone);
      formData.append("companyRep1", userName);
      formData.append("monthlyPremium", convertToPrice(response.data.totalpremium / 12));
      formData.append("powerUnits", insuranceForm.trucks);
      formData.append("totalDrivers", insuranceForm.drivers);
      formData.append("legalName", insuranceForm.companyName);
      formData.append("dotNumber", insuranceForm.dotNumber);
      formData.append("totalPremium", response.data.totalpremium);
      formData.append("coverageStartDate", validDate);
      formData.append("garageState", insuranceForm.garageState);
      formData.append("garageZip", insuranceForm.garageZip);
      formData.append("radius", radius);
      formData.append("yearsInBusiness", insuranceForm.businessYears);
      formData.append("source", source);
      formData.append("help", followingHelp);
      await finalizeQuoteAxios(formData);
    };
    return response;
  } catch (error) {
    console.log(error);
  };
};

export const postDriver = async (formData) => {
  try {
    const response = await axios.post(baseURL + 'addDriver', formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
  } catch (error) {
    console.log(error);
    return error.response;
  };
};

export const getAllSubmissionsAxios = async (dotNumber) => {
  try {
    const response = await axios.get(baseURL + `get-quote/${dotNumber}`);
    return response;
  } catch (error) {
    console.log(error);
  };
};

export const getTotalCSVAxios = async (row) => {
  try {
    const vehiclesArray = [];
    for (let i = 0; i < +row.trucks; i++) {
      vehiclesArray.push({
        vin: '1M2AT13C67M003360',
        year: '2015',
        make: 'Freightliner',
        model: 'Cascadia',
        value: 30000,
        classKey: "8",
        bodyTypeKey: "tractor",
        deductibleType: "FollowsPolicy",
        deductible: "1000"
      });
    };
    const data = {
      dotNumber: row.dotNumber,
      yearsInBusiness: row.businessYears,
      coverageStartDate: today,
      legalName: row.companyName,
      garageState: row.garageState,
      garageZip: row.garageZip,
      garageCity: row.garageCity,
      garageStreet: row.garageStreet,
      powerUnits: row.trucks,
      totalDrivers: row.drivers,
      vehicles: vehiclesArray,
      coverage: {
        al: "Y",
        ntl: "Y",
        pd: "Y",
        mtc: "Y",
        gl: "Y"
      },
      companyRep1: 'companyRep1',
      telephone: 'phone',
      emailAddress: 'user@mail.com'
    };
    const response = await axios.post(baseURL + 'website-submission', data);
    if (response.data.totalpremium) {
      const pdfUserBlob = await pdf(<CompanyPDF2 pdfData={row} coverage={response.data.totalpremium} />).toBlob();
      const formData = new FormData();
      formData.append("pdf", pdfUserBlob, `${row.dotNumber}`);
      const res = await axios.post(PDFURL , formData);
      const pdfLink = res.data.split('URL: ');
      const linkData = {
        url: pdfLink[1],
        dotNumber: row.dotNumber,
        totalpremium: response.data.totalpremium
      };
      return linkData;
    };
    return {
      url: 'error',
      dotNumber: row.dotNumber,
      totalpremium: 0
    };
  } catch (error) {
    console.log(error);
    return {
      url: 'error',
      dotNumber: row.dotNumber,
      totalpremium: 0
    };
  };
};
