import { useState } from "react";
import Pagination from "../Pagination/Pagination";

const AllSubmissionsTable = ({submissions}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const submissionsPerPage = 5;
  const indexOfLastSubmission = currentPage * submissionsPerPage;
  const indexOfFirstSubmission = indexOfLastSubmission - submissionsPerPage;
  const currentSubmissions = submissions.slice(indexOfFirstSubmission, indexOfLastSubmission);

  const paginateFront = () => setCurrentPage(currentPage + 1);
  const paginateBack = () => setCurrentPage(currentPage - 1);

  return (
    <div className="flex flex-col lg:w-11/12 mb-8">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h2 className="text-base lg:text-4xl font-semibold leading-6 text-gray-900">Submissions</h2>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the submission quotes.
            </p>
          </div>
        </div>
        <div className="mt-4 flow-root">
          <div className="-my-2 overflow-x-auto block no-scrollbar">
            <div className="inline-block min-w-full py-2 align-middle">
              <table className="min-w-full h-64 divide-y divide-gray-300">
                <thead className="bg-blue-900 text-white border-b sticky top-0">
                  <tr>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold">
                      Legal Name
                    </th>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold sm:pl-3">
                      Submission id
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold">
                      Vehicles
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold">
                      Radius
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold">
                      Drivers
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold">
                      Start Date
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {currentSubmissions.map((submission, personIdx) => (
                    <tr key={submission._id} className={`${personIdx % 2 === 0 ? undefined : 'bg-gray-50'}`}>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-left text-gray-500">
                        {submission.quoteData.legalName}
                      </td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                        {submission._id}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {submission.quoteData.powerUnits}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {submission.quoteData.radius === '0' && '0 - 50'}
                        {submission.quoteData.radius === '51' && '51 - 200'}
                        {submission.quoteData.radius === '201' && '0 - 500'}
                        {submission.quoteData.radius === '501' && '501 +'}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {submission.quoteData.totalDrivers}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {submission.quoteData.coverageStartDate}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
          {submissions.length > 5 && 
            <Pagination
              itemsPerPage={submissionsPerPage}
              totalItems={submissions.length}
              paginateBack={paginateBack}
              paginateFront={paginateFront}
              currentPage={currentPage}
            />
          }
      </div>
    </div>
  );
};

export default AllSubmissionsTable;
