import { useState } from "react";
import { Steps, useSteps } from "react-step-builder";
import ComercialTruck from "../ComercialTruck/ComercialTruck";
import { getTotalAxios, sendDotAxios, step1Axios, step2Axios, step4Axios, step5Axios } from "../../api/axiosFunctions";
import Circles from "./Circles";
import arrowRight from "../../images/arrow-right.svg";
import arrowLeft from "../../images/arrow-left.svg";
import mailIMG from "../../images/mail.svg";
import userIMG from "../../images/user.svg";
import phoneIMG from "../../images/phone.svg";
import usStates from '../../data/us-states-with-quotes.json';
import './MultiStepsForm.css';

const NewCostumerMultiStepsForm = () => {
  const { next, prev, current, total } = useSteps();
  let today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0');
  const yyyy = today.getFullYear();
  today = yyyy + '-' + mm + '-' + dd;
  const [comercialForm, setComercialForm] = useState(false);
  const [firstLoading, setFirstLoading] = useState(false);
  const [secondLoading, setSecondLoading] = useState(false);
  const [finalLoading, setFinalLoading] = useState(false);
  const [loadingText, setLoadingText] = useState(1);
  const [secondLoadingText, setSecondLoadingText] = useState(false);
  const [dotChecked, setDotChecked] = useState(false);
  const [invalidState, setInvalidState] = useState(false);
  const [invalidUser, setInvalidUser] = useState(false);
  const [invalidCoverage, setInvalidCoverage] = useState(false);
  const [invalidPremium, setInvalidPremium] = useState(false);
  const [invalidStep2, setInvalidStep2] = useState(false);
  const [textMessagesChecked, setTextMessagesChecked] = useState(true);
  const [premium, setPremium] = useState(0);
  const [insuranceForm, setInsuranceForm] = useState({
    dotNumber: '',
    businessYears: '',
    coverageStartDate: today,
    companyName: '',
    garageState: 'selectState',
    garageZip: '',
    garageStreet: '',
    garageCity: '',
    trucks: '',
    drivers: '',
    userName: '',
    email: '',
    phone: '',
    radiusDriving: '0-50',
    opsTruck: 'opsLocal'
  });
  const [coverage, setCoverage] = useState({
    al: true,
    ntl: false,
    pd: true,
    mtc: true,
    gl: true
  });
  const [radiusDriving, setRadiusDriving] = useState({
    radius0_50: true,
    radius51_200: false,
    radius201_500: false,
    radius501: false
  });

  const handleChange = (event) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInsuranceForm((prevState)=> {
      return({
        ...prevState,
        [inputName]: newValue,
      });
    });
  };

  const handleCoverageChange = (event) => {
    const inputName = event.target.name;
    setCoverage((prevState)=> {
      return({
        ...prevState,
        [inputName]: !coverage[inputName],
      });
    });
  }

  const handleRadiusChange = (event) => {
    const inputName = event.target.name;
    setRadiusDriving((prevState) => {
      return {
        ...prevState,
        [inputName]: !radiusDriving[inputName]
      };
    });
  };

  const transformRadius = (radius) => {
    let count = 0;

    for (let key in radius) {
      if (radius[key] === true) {
        count++;
      }
    }

    const percentage = Math.floor(100 / count);

    for (let key in radius) {
      if (radius[key] === true) {
        radius[key] = percentage;
      } else {
        radius[key] = 0;
      }
    }
    return radius;
  };

  const generateVehiclesArray = (insuranceForm) => {
    const vehiclesArray = [];
    if (
      ![
        "opsDumpTruckSandGravel",
        "opsHotShot",
        "opsTowing",
        "opsLongHaul"
      ].includes(insuranceForm.opsTruck)
    ) {
      for (let i = 0; i < +insuranceForm.trucks; i++) {
        vehiclesArray.push({
          vin: "1M2AT13C67M003360",
          year: "2015",
          make: "Freightliner",
          model: "Cascadia",
          value: 30000,
          classKey: "8",
          bodyTypeKey: "tractor",
          deductibleType: "FollowsPolicy",
          deductible: "1000"
        });
      }
    }
    if (insuranceForm.opsTruck === "opsDumpTruckSandGravel") {
      for (let i = 0; i < +insuranceForm.trucks; i++) {
        vehiclesArray.push({
          vin: "1FDNF7DC2KDF08124",
          year: "2019",
          make: "Ford",
          model: "F-750",
          value: 85000,
          classKey: "8",
          bodyTypeKey: "tractor",
          deductibleType: "FollowsPolicy",
          deductible: "1000"
        });
      }
    }
    if (insuranceForm.opsTruck === "opsHotShot") {
      for (let i = 0; i < +insuranceForm.trucks; i++) {
        vehiclesArray.push({
          vin: "1FT8W3DTXFEB10704",
          year: "2015",
          make: "Ford",
          model: "F-350",
          value: 40000,
          classKey: "8",
          bodyTypeKey: "tractor",
          deductibleType: "FollowsPolicy",
          deductible: "1000"
        });
      }
    }
    if (insuranceForm.opsTruck === "opsTowing") {
      for (let i = 0; i < +insuranceForm.trucks; i++) {
        vehiclesArray.push({
          vin: "3C7WRMDL7KG521987",
          year: "2019",
          make: "Ram",
          model: "5500 HD",
          value: 85000,
          classKey: "8",
          bodyTypeKey: "tractor",
          deductibleType: "FollowsPolicy",
          deductible: "1000"
        });
      }
    }
    if (insuranceForm.opsTruck === "opsLongHaul") {
      for (let i = 0; i < +insuranceForm.trucks; i++) {
        vehiclesArray.push({
          vin: "1FUJHHDR8LLLN1514",
          year: "2020",
          make: "Freightliner",
          model: "Cascadia",
          value: 75000,
          classKey: "8",
          bodyTypeKey: "tractor",
          deductibleType: "FollowsPolicy",
          deductible: "1000"
        });
      }
    }
    return vehiclesArray;
  };

  const generateDriversArray = (insuranceForm) => {
    const driversArray = [];
    for (let i = 0; i < +insuranceForm.drivers; i++) {
      driversArray.push({
        firstName: `Ralph`,
        lastName: `Machio`,
        licenseState: "CA",
        licenseNumber: "string",
        dateOfBirth: `12/12/1974`,
        dateOfHire: `12/12/2020`,
        yearsExperience: "2",
        eligibility: "Covered",
        accidents_total: "0",
        violations_total: "0",
        suspensions_total: "0"
      });
    }
    return driversArray;
  };

  const handleStep1Submit = async (event) => {
    event.preventDefault();
    setFirstLoading(true);
    if (dotChecked || ['0', ''].includes(insuranceForm.dotNumber)) {
      const dotNumber = Math.random().toString().slice(2,10);
      next();
      setTimeout(() => {setFirstLoading(false)},3000);
      setInsuranceForm((prevState)=> {
        return({
          ...prevState,
          "dotNumber": dotNumber,
        });
      });
      step1Axios(dotNumber, insuranceForm.businessYears, insuranceForm.coverageStartDate, insuranceForm.opsTruck);
      return;
    };
    const response = await sendDotAxios(insuranceForm.dotNumber);
    if (response.data.error || Object.keys(response.data).length === 0) {
      next();
      setTimeout(() => {setFirstLoading(false)},3000);
      return;
    };
    setInsuranceForm((prevState)=> {
      return({
        ...prevState,
        "garageStreet": response.data.generalDot?.phyStreet || 'Street Not Found',
        "garageCity": response.data.generalDot?.phyCity || 'City Not Found',
        "garageState": response.data.generalDot?.phyState || 'State Not Found',
        "garageZip": response.data.generalDot?.phyZip || 'ZIP State Not Found',
        "drivers": response.data.generalDot?.totalDrivers || '0',
        "trucks": response.data.generalDot?.powerUnits || '0',
        "companyName": response.data.generalDot?.legalName || 'Company Not Found',
        "premiums": response.data.premiums
      });
    });
    step1Axios(insuranceForm.dotNumber, insuranceForm.businessYears, insuranceForm.coverageStartDate, insuranceForm.opsTruck);
    setTimeout(() => {setFirstLoading(false)},3000);
    next();
  };

  const handleStep2Submit = async (event) => {
    event.preventDefault();
    setInvalidStep2(false);
    setInvalidState(false);
    if (insuranceForm.garageState === 'selectState') {
      setInvalidState(true);
      return;
    };
    if ([insuranceForm.companyName, insuranceForm.garageZip, insuranceForm.garageStreet, insuranceForm.garageCity, insuranceForm.trucks, insuranceForm.drivers].some((element) => element === '')) {
      setInvalidStep2(true);
      return;
    };
    setSecondLoading(true);
    setSecondLoadingText(true);
    step2Axios(insuranceForm.dotNumber, insuranceForm.companyName, insuranceForm.garageState, insuranceForm.garageZip, insuranceForm.garageStreet, insuranceForm.garageCity, insuranceForm.trucks, insuranceForm.drivers)
    setTimeout(() => {setSecondLoadingText(false)},3000);
    setTimeout(() => {setSecondLoading(false)},6000);
    next();
  };

  const handleStep4Submit = (event) => {
    event.preventDefault();
    setInvalidCoverage(false);
    if (Object.values(coverage).every(item => !item)) {
      setInvalidCoverage(true);
      return;
    };
    step4Axios(insuranceForm.dotNumber, insuranceForm.radiusDriving, coverage);
    next();
  };

  const handleFinalSubmit = async (event) => {
    event.preventDefault();
    setInvalidUser(false);
    setInvalidPremium(false);
    if (insuranceForm.userName === '' || insuranceForm.email === '' || insuranceForm.phone === '') {
      setInvalidUser(true);
      return;
    };
    setFinalLoading(true);
    setLoadingText(1);
    setTimeout(() => {setLoadingText(2)},2000);
    setTimeout(() => {setLoadingText(3)},5000);
    step5Axios(insuranceForm.dotNumber, insuranceForm.email, insuranceForm.phone, insuranceForm.userName);
    const source = 'costumer page (/newversion)';
    const vehiclesArray = generateVehiclesArray(insuranceForm);
    const driversArray = generateDriversArray(insuranceForm, source);
    const radius = transformRadius({ ...radiusDriving });
    const followingHelp = 'No need help';
    const finalResponse = await getTotalAxios(
      insuranceForm,
      vehiclesArray,
      driversArray,
      coverage,
      radius,
      source,
      followingHelp
    );
    if (finalResponse?.data?.totalpremium > 0) {
      setPremium(finalResponse.data.totalpremium);
      setComercialForm(true);
    } else {
      setInvalidPremium(true);
    };
    setFinalLoading(false);
  };

  return (
    <div className="multiStepsFormPage">
      { comercialForm ?
        <ComercialTruck insuranceData={insuranceForm} coverage={premium} />
        :
        <>
          { current > 1 && <h2 className="lg:text-3xl text-xl">RAPID QUOTE</h2>}
          { current === 1 &&
            <div className="w-11/12 flex flex-col items-center mb-4 sm:mb-0">
              <p className="font-bold my-3 lg:text-3xl text-xl">Welcome to American Fleet's Rapid Quote.</p>
              <div
                className="relative w-5/6 flex justify-center mt-auto pt-[55%] sm:pt-[35%] lg:pt-[20%] sm:w-1/2 mb:w-1/3"
              >
                <iframe
                  src="https://player.vimeo.com/video/803142341?h=afe1b491df&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  allow="autoplay; picture-in-picture"
                  allowFullScreen={true}
                  className="absolute top-0 left-0 w-[100%] h-[100%] lg:left-auto"
                  title="Rapid Quote"
                >
                </iframe>
              </div>
            </div>
          }
          <div className="circlesDiv">
            <Circles circleNumber={total} current={current} />
          </div>
          <Steps>
            <form onSubmit={handleStep1Submit} className="min-h-[370px] flex flex-col justify-between lg:w-3/5 lg:min-h-0">
              <label className="CostumerMultiStepsLabel">
                <h4 className="w-fit pl-4 text-base lg:text-xl">DOT Number</h4>
                {!dotChecked && <input name="dotNumber" type="number" pattern="\d*" className="multistepsinput" id="dotNumberInput" onChange={handleChange} value={insuranceForm.dotNumber} />}
                <label className="flex items-center pl-4 text-base lg:text-xl hover:cursor-pointer">
                  <input type="checkbox" className="h-4 w-4 mr-4 rounded border-gray-400 text-[#3056d3] focus:ring-[#3056d3]" checked={dotChecked} onChange={() => setDotChecked(!dotChecked)} />
                  I do not have a DOT Number yet
                </label>
              </label>
              <label className="CostumerMultiStepsLabel">
                <h4 className="w-fit pl-4 text-base lg:text-xl">Business Type</h4>
                <select name="opsTruck" className="multiStepsselect" onChange={handleChange} value={insuranceForm.opsTruck}>
                  <option value="opsLocal">Local Trucker</option>
                  <option value="opsLongHaul">Long Haul Trucker</option>
                  <option value="opsHotShot">Hot Shot</option>
                  <option value="opsDumpTruckSandGravel">Dump Truck (Sand and Gravel)</option>
                  <option value="opsTowing">Towing</option>
                  <option value="opsOther">Other</option>
                </select>
              </label>
              <label className="CostumerMultiStepsLabel">
                <h4 className="w-fit pl-4 text-base lg:text-xl">Number of Years in Business</h4>
                <input name="businessYears" type="number" pattern="\d*" className="multistepsinput" onChange={handleChange} value={insuranceForm.businessYears} />
              </label>
              <label className="CostumerMultiStepsLabel mb-auto">
                <h4 className="w-fit pl-4 text-left text-base lg:text-xl">When would you like your coverage to start?</h4>
                <input name="coverageStartDate" type="date" className="multistepsinput appearance-none" onChange={handleChange} value={insuranceForm.coverageStartDate} />
              </label>
              <button type="submit" className="flex justify-center items-center font-bold text-white bg-[#3056d3] rounded-lg py-2 px-8 my-2 lg:mt-4">
                <p className="w-fit lg:text-xl">Continue</p>
                <img src={arrowRight} alt='right arrow' className="w-6 lg:w-8 mt-0.5 lg:mt-1 ml-2" />
              </button>
            </form>
            <form onSubmit={handleStep2Submit}  className="grid grid-cols-2 gap-x-2 lg:gap-x-12 min-h-[55vh] lg:w-3/5 whitespace-nowrap">
              <h3 className="text-sm lg:text-xl col-span-2">Please confirm, edit or complete the below information:</h3>
              <label className="CostumerMultiStepsLabel col-span-2">
                <h4 className="w-fit pl-4 text-xl">Company Name</h4>
                <input name="companyName" type="text" className="multistepsinput" onChange={handleChange} value={insuranceForm.companyName} />
              </label>
              <label className="CostumerMultiStepsLabel col-span-2">
                <h4 className="w-fit pl-4 text-xl">Street</h4>
                <input name="garageStreet" type="text" className="multistepsinput" onChange={handleChange} value={insuranceForm.garageStreet} />
              </label>
              <label className="CostumerMultiStepsLabel col-span-2">
                <h4 className="w-fit pl-4 text-xl">City</h4>
                <input name="garageCity" type="text" className="multistepsinput" onChange={handleChange} value={insuranceForm.garageCity} />
              </label>
              <label className="CostumerMultiStepsLabel">
                <h4 className="w-fit lg:pl-4 text-lg lg:text-xl">Garage State</h4>
                <select name="garageState" className="border-2 border-[#cecece] rounded-lg mt-2 lg:p-2.5" onChange={handleChange} value={insuranceForm.garageState}>
                  <option value="selectState">Select State</option>
                  {usStates.map((state) => (
                    <option key={state.id} value={state.value_back}>{state.value}</option>
                  ))}
                </select>
                {invalidState && <p className="text-red-700 text-left text-sm pl-4 -mt-2">Please select a State</p>}
              </label>
              <label className="CostumerMultiStepsLabel">
                <h4 className="w-fit lg:pl-4 text-lg lg:text-xl">Garage Zip Code</h4>
                <input name="garageZip" type="number" pattern="\d*" className="multistepsinput" onChange={handleChange} value={insuranceForm.garageZip} />
              </label>
              <label className="CostumerMultiStepsLabel">
                <h4 className="w-fit lg:pl-4 text-lg lg:text-xl">Number of Trucks</h4>
                <input name="trucks" type="number" pattern="\d*" className="multistepsinput" onChange={handleChange} value={insuranceForm.trucks} />
              </label>
              <label className="CostumerMultiStepsLabel mb-4">
                <h4 className="w-fit lg:pl-4 text-lg lg:text-xl">Number of Drivers</h4>
                <input name="drivers" type="number" pattern="\d*" className="multistepsinput" onChange={handleChange} value={insuranceForm.drivers} />
              </label>
              {invalidStep2 && <p className="text-red-700 text-sm font-bold mt-2 mb-4 lg:col-span-2 lg:text-lg">Please Complete All Fields</p>}
              <div className="flex items-center justify-around my-auto col-span-2">
                <button type="button" className="flex justify-center items-center font-bold text-white bg-[#d43030] rounded-lg h-12 w-2/5 px-8" onClick={prev}>
                  <img src={arrowLeft} alt='previous page' className="w-6 lg:w-8 mt-0.5 lg:mt-1 mr-2" />
                  <p className="w-fit lg:text-xl">Previous</p>
                </button>
                <button type="submit" className="flex justify-center items-center font-bold text-white bg-[#3056d3] rounded-lg h-12 w-2/5 px-8">
                  <p className="w-fit lg:text-xl">Continue</p>
                  <img src={arrowRight} alt='right arrow' className="w-6 lg:w-8 mt-0.5 lg:mt-1 ml-2" />
                </button>
              </div>
            </form>
            <form onSubmit={handleStep4Submit} className="multiStepsForm items-center">
              <div className="p-4 flex flex-col md:flex-row md:items-start">
                <fieldset className="gap-y-6 md:gap-y-8 flex flex-col items-start md:mt-8 lg:mt-0">
                  <h4 className="w-fit mt-2 md:mt-0 text-xl font-bold">Radius of driving</h4>
                  <legend className="sr-only">Radius of driving</legend>
                  <label className="flex h-5 items-center hover:cursor-pointer font-medium text-gray-700 text-xl gap-x-8 w-fit">
                    <input
                      id="0miles"
                      aria-describedby="0-50 miles"
                      name="radius0_50"
                      type="checkbox"
                      checked={radiusDriving.radius0_50}
                      onChange={handleRadiusChange}
                      value="0-50"
                      className="h-4 w-4 rounded border-gray-300 text-[#542670] focus:ring-[#542670]"
                    />
                    0 - 50 Miles
                  </label>
                  <label className="flex h-5 items-center hover:cursor-pointer font-medium text-gray-700 text-xl gap-x-8 w-fit">
                    <input
                      id="51miles"
                      aria-describedby="51 - 200 Miles"
                      name="radius51_200"
                      type="checkbox"
                      checked={radiusDriving.radius51_200}
                      onChange={handleRadiusChange}
                      value="51-200"
                      className="h-4 w-4 rounded border-gray-300 text-[#542670] focus:ring-[#542670]"
                    />
                    51 - 200 Miles
                  </label>
                  <label className="flex h-5 items-center hover:cursor-pointer font-medium text-gray-700 text-xl gap-x-8 w-fit">
                    <input
                      id="201miles"
                      aria-describedby="201 - 500 Miles"
                      name="radius201_500"
                      type="checkbox"
                      checked={radiusDriving.radius201_500}
                      onChange={handleRadiusChange}
                      value="201-500"
                      className="h-4 w-4 rounded border-gray-300 text-[#542670] focus:ring-[#542670]"
                    />
                    201 - 500 Miles
                  </label>
                  <label className="flex h-5 items-center hover:cursor-pointer font-medium text-gray-700 text-xl gap-x-8 w-fit">
                    <input
                      id="501miles"
                      aria-describedby="01+ Miles"
                      name="radius501"
                      type="checkbox"
                      checked={radiusDriving.radius501}
                      onChange={handleRadiusChange}
                      value="501+"
                      className="h-4 w-4 rounded border-gray-300 text-[#542670] focus:ring-[#542670]"
                    />
                    501+ Miles
                  </label>
                </fieldset>
                <fieldset className="gap-y-6 md:gap-y-8 flex flex-col items-start my-8 lg:mt-0">
                  <h4 className="w-fit text-xl mr-auto font-bold">Select your coverage(s)</h4>
                  <legend className="sr-only">Select your coverage(s)</legend>
                  <label className="flex h-5 items-center hover:cursor-pointer font-medium text-gray-700 text-xl gap-x-8 w-fit">
                    <input
                      id="al"
                      aria-describedby="Automotive Liability"
                      name="al"
                      type="checkbox"
                      checked={coverage.al}
                      onChange={handleCoverageChange}
                      className="h-4 w-4 rounded border-gray-300 text-[#3056d3] focus:ring-[#3056d3]"
                    />
                      Automotive Liability
                  </label>
                  <label className="flex h-5 items-center hover:cursor-pointer font-medium text-gray-700 text-xl gap-x-8 w-fit">
                    <input
                      id="ntl"
                      aria-describedby="Non-Trucking Liability"
                      name="ntl"
                      type="checkbox"
                      checked={coverage.ntl}
                      onChange={handleCoverageChange}
                      className="h-4 w-4 rounded border-gray-300 text-[#3056d3] focus:ring-[#3056d3]"
                    />
                      Non-Trucking Liability
                  </label>
                  <label className="flex h-5 items-center hover:cursor-pointer font-medium text-gray-700 text-xl gap-x-8 w-fit">
                    <input
                      id="pd"
                      aria-describedby="Physical Damage"
                      name="pd"
                      type="checkbox"
                      checked={coverage.pd}
                      onChange={handleCoverageChange}
                      className="h-4 w-4 rounded border-gray-300 text-[#3056d3] focus:ring-[#3056d3]"
                    />
                      Physical Damage
                  </label>
                  <label className="flex h-5 items-center hover:cursor-pointer font-medium text-gray-700 text-xl gap-x-8 w-fit">
                    <input
                      id="mtc"
                      aria-describedby="Motor Truck Cargo"
                      name="mtc"
                      type="checkbox"
                      checked={coverage.mtc}
                      onChange={handleCoverageChange}
                      className="h-4 w-4 rounded border-gray-300 text-[#3056d3] focus:ring-[#3056d3]"
                    />
                      Motor Truck Cargo
                  </label>
                  <label className="flex h-5 items-center hover:cursor-pointer font-medium text-gray-700 text-xl gap-x-8 w-fit">
                    <input
                      id="gl"
                      aria-describedby="General Liability"
                      name="gl"
                      type="checkbox"
                      checked={coverage.gl}
                      onChange={handleCoverageChange}
                      className="h-4 w-4 rounded border-gray-300 text-[#3056d3] focus:ring-[#3056d3]"
                    />
                      General Liability
                  </label>
                </fieldset>
              </div>
              {invalidCoverage && <p className="text-red-700 text-sm font-bold mb-2">Please Select at least one coverage</p>}
              <div className="flex items-center justify-around lg:justify-between my-auto lg:col-span-2">
                <button type="button" className="flex justify-center items-center font-bold text-white bg-[#d43030] rounded-lg h-12 w-2/5 px-8" onClick={prev}>
                  <img src={arrowLeft} alt='previous page' className="w-6 lg:w-8 mt-0.5 lg:mt-1 mr-2" />
                  <p className="w-fit lg:text-xl">Previous</p>
                </button>
                <button type="submit" className="flex justify-center items-center font-bold text-white bg-[#3056d3] rounded-lg h-12 w-2/5 px-8">
                  <p className="w-fit lg:text-xl">Continue</p>
                  <img src={arrowRight} alt='right arrow' className="w-6 lg:w-8 mt-0.5 lg:mt-1 ml-2" />
                </button>
              </div>
            </form>
            <form onSubmit={handleFinalSubmit}  className="multiStepsForm">
              <p className="lg:text-3xl my-4 font-bold text-sm">Once you fill out your information below our technology will search A+ carriers and present to you a quote with the best coverage at the lowest cost.</p>
              <label className="CostumerMultiStepsLabel">
                <h4 className="w-fit pl-4 text-xl">Your Name</h4>
                <img src={userIMG} alt='insert your mail' className="w-6 absolute ml-[20px] mt-[46px]" />
                <input name="userName" type="text" className="bg-white text-black border-[2px] rounded-lg border-[#cecece] py-[8px] pl-14 my-2" onChange={handleChange} value={insuranceForm.userName} />
              </label>
              <label className="CostumerMultiStepsLabel">
                <div className="flex justify-start items-center">
                  <h4 className="w-fit pl-4 text-left text-xl">Email</h4>
                  <p className="text-sm w-fit ml-2 italic">(Where we will email your quote)</p>
                  <img src={mailIMG} alt='insert your mail' className="w-6 absolute ml-[20px] mt-[88px]" />
                </div>
                <input name="email" type="email" className="bg-white text-black border-[2px] rounded-lg border-[#cecece] py-[8px] pl-14 my-2" onChange={handleChange} value={insuranceForm.email} />
              </label>
              <label className="CostumerMultiStepsLabel">
                <div className="flex justify-start items-center">
                  <h4 className="w-fit pl-4 text-left text-xl">Cell Phone</h4>
                  <p className="text-sm w-fit ml-2 italic">(Where we will text your quote)</p>
                  <img src={phoneIMG} alt='insert your mail' className="w-6 absolute ml-[20px] mt-[88px]" />
                </div>
                <input name="phone" type="number" pattern="\d*" className="bg-white text-black border-[2px] rounded-lg border-[#cecece] py-[8px] pl-14 my-2" onChange={handleChange} value={insuranceForm.phone} />
              </label>
              <label className="flex items-center hover:cursor-pointer font-bold text-gray-700 gap-x-2 mt-2">
                <input
                  id="textMessages"
                  aria-describedby="textMessages"
                  name="textMessages"
                  type="checkbox"
                  checked={textMessagesChecked}
                  onChange={() => setTextMessagesChecked(!textMessagesChecked)}
                  className="h-6 w-6 rounded border-2 hover:cursor-pointer border-gray-900 text-[#3056d3] focus:ring-[#3056d3]"
                />
                <div>
                  <p className="text-sm">I agree to receive text messages from American Fleet Insurance regarding my quote and announcements.</p>
                  <p className="text-[10px]">Reply STOP to stop receiving messages. Frequency varies. Message and data rates might apply. Access our Terms and Conditions and Privacy Policy.</p>
                </div>
              </label>
              <div className="my-8">
                {invalidUser && <p className="text-red-700 text-sm font-bold mb-2">Please complete all fields</p>}
                {invalidPremium && <p className="text-red-700 text-sm font-bold mb-2">There was a problem with your coverage</p>}
                <div className="flex items-center justify-around lg:justify-between">
                  <button type="button" className="flex justify-center items-center font-bold text-white bg-[#d43030] rounded-lg h-12 w-2/5 px-8" onClick={prev}>
                    <img src={arrowLeft} alt='previous page' className="w-6 lg:w-8 mt-0.5 lg:mt-1 mr-2" />
                    <p className="w-fit lg:text-xl">Previous</p>
                  </button>
                  <button type="submit" className="flex justify-center items-center font-bold text-white bg-[#3056d3] rounded-lg h-12 w-2/5 px-8">
                    <p className="w-fit lg:text-xl">Submit</p>
                    <img src={arrowRight} alt='right arrow' className="w-6 lg:w-8 mt-0.5 lg:mt-1 ml-2" />
                  </button>
                </div>
              </div>
            </form>
          </Steps>
        </>
      }
      { finalLoading && (
        <div className="loader">
          { loadingText === 1 && <p className="text-xl font-bold mb-6">Shopping A+ Carriers </p> }
          { loadingText === 2 && <p className="text-xl font-bold mb-6 w-9/12">Finding the best coverage for the lowest price </p> }
          { loadingText === 3 && <p className="text-xl font-bold mb-6">Preparing your quote </p> }
          <div className="spinner" />
        </div>
      )}
      { firstLoading && (
        <div className="loader">
            <p className="text-xl font-bold mb-6">Gathering Company Information</p>
          <div className="spinner" />
        </div>
      )}
      { secondLoading && (
        <div className="loader">
          {secondLoadingText ?
            <p className="text-xl font-bold mb-6">Gathering Vehicle Information </p>
          :
            <p className="text-xl font-bold mb-6">Gathering Driver Information </p>
          }
          <div className="spinner" />
        </div>
      )}
    </div>
  );
};

export default NewCostumerMultiStepsForm;
