import { useState } from "react";
import { useLocation } from "react-router-dom";
import { RxCopy } from "react-icons/rx";
import AddDriversModal from "../components/modals/AddDriversModal";

const AddDrivers = () => {
  const location = useLocation();
  const dotNumber = location.state?.dotNumber || "0";
  const page = location.state?.location || "";
  const drivers = location.state?.drivers || "";
  const [loading, setLoading] = useState(true);
  const [isCopied, setIsCopied] = useState(false);
  const [modalDriver, setModalDriver] = useState("modal_inactive");
  const copyText = `https://www.quote.americanfleetinsurance.com/addDriver/${dotNumber}`;

  const handleCopyLink = async () => {
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(
        `Hey guys, I am shopping for insurance. Please add your information by submitting the form below. I need this quickly please do it as soon as possible: ${copyText}`
      );
    } else {
      document.execCommand(
        "copy",
        true,
        `Hey guys, I am shopping for insurance. Please add your information by submitting the form below. I need this quickly please do it as soon as possible: ${copyText}`
      );
    }
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };
  setTimeout(() => {
    setLoading(false);
  }, "1500");

  return (
    <div className="flex flex-col items-center pt-2">
      <h2 className="lg:text-3xl lg:mt-4 my-10">RAPID QUOTE</h2>
      <h3 className="lg:text-xl w-3/4 my-2">
        All we need now to finalize your quote are all your driver's
        information.{" "}
      </h3>
      {page !== "/geotab" && (
        <>
          <h3 className="lg:text-xl w-3/4 my-2">
            Please watch the quick video below:
          </h3>
          <div className="w-2/3 flex flex-col items-center mb-4">
            <div className="relative flex justify-center mt-auto pt-[55%] lg:pt-[30%]">
              <iframe
                src="https://player.vimeo.com/video/803147803?h=e8141cc404&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                allow="autoplay; picture-in-picture"
                allowFullScreen={true}
                className="absolute top-0 left-0 w-[100%] h-[100%] lg:left-auto"
                title="Rapid Quote"
              ></iframe>
            </div>
          </div>
        </>
      )}
      <div className="flex flex-col items-center w-5/6 mb-10 lg:w-3/5">
        <h3 className="lg:text-xl w-3/4 my-2">
          Copy the link below and text it to your {drivers}{" "}
          {drivers > 1 ? "drivers" : "driver"}
        </h3>
        <h3 className="lg:text-xl w-3/4 my-2">
          We will let you know when each driver completes the form.
        </h3>
        <div className="flex items-center mt-4 border-slate-300 border-2 rounded-3xl py-2 pl-1 pr-2 lg:w-1/2 lg:mt-8">
          <input
            type="text"
            value={copyText}
            className="bg-white border-0 lg:px-2 focus:border-transparent focus:ring-0"
            readOnly
          />
          <button type="button" onClick={handleCopyLink} className="w-fit">
            <RxCopy />
          </button>
        </div>
        {isCopied && <span>'Copied!'</span>}
        <p className="font-bold text-xl my-4">OR</p>
        <p className="lg:text-xl w-4/5 my-2">
          If you have your {drivers > 1 ? "drivers" : "driver"} information
          available, add each driver.
        </p>
        <button
          type="button"
          onClick={() => setModalDriver("modal_active")}
          className="rounded-3xl w-5/6 text-white font-bold py-2 px-8 bg-green-700 mt-6 lg:w-1/2 lg:text-xl"
        >
          + Add Driver
        </button>
      </div>
      <div className={modalDriver}>
        <AddDriversModal
          setModalDriver={setModalDriver}
          dotNumber={dotNumber}
        />
      </div>
      {loading && (
        <div className="loader">
          <div className="spinner" />
        </div>
      )}
    </div>
  );
};

export default AddDrivers;
