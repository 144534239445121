import { useNavigate } from "react-router-dom";
import otherTruck from "../../images/truck2tiny.png";
import dumpTruck from "../../images/dump-truck.png";
import hotShotTruck from "../../images/hot-shot.png";
import towTruck from "../../images/tow-truck.png";
import producerImage from "../../images/Stephen-smtiny.png";
import arrowRight from "../../images/arrow-right.svg";

const CommercialTruck = ({ insuranceData, coverage, location }) => {
  const navigate = useNavigate();
  const address = [
    `${insuranceData.garageStreet}`,
    `${insuranceData.garageCity}, ${insuranceData.garageState} ${insuranceData.garageZip}`
  ];

  const numberWithCommas = (x) => {
    return x
      .toFixed()
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <div className="flex flex-col items-center min-h-[90vh]">
      <div className="flex flex-col items-center text-xl font-bold xl:text-3xl">
        <p>Commercial Trucking Quote for:</p>
        <p className="my-2 xl:underline">{insuranceData.companyName}</p>
      </div>
      <div className="flex self-start justify-between items-center">
        {!["opsHotShot", "opsDumpTruckSandGravel", "opsTowing"].includes(
          insuranceData.opsTruck
        ) && (
          <img
            src={otherTruck}
            alt="truck"
            className="w-5/12 md:w-1/3 md:mx-auto"
          />
        )}
        {insuranceData.opsTruck === "opsDumpTruckSandGravel" && (
          <img
            src={dumpTruck}
            alt="truck"
            className="w-5/12 md:w-1/3 md:mx-auto"
          />
        )}
        {insuranceData.opsTruck === "opsHotShot" && (
          <img
            src={hotShotTruck}
            alt="truck"
            className="w-5/12 md:w-1/3 md:mx-auto"
          />
        )}
        {insuranceData.opsTruck === "opsTowing" && (
          <img
            src={towTruck}
            alt="truck"
            className="w-5/12 md:w-1/3 md:mx-auto"
          />
        )}
        <div className="w-1/2 bg-yellow-200 font-bold flex flex-col justify-center items-center h-full py-2 md:py-4">
          <p className="mb-1 text-xs md:text-xl xl:text-3xl">
            Estimated Monthly Premium
          </p>
          <p className="text-4xl md:text-6xl xl:text-7xl">
            ${numberWithCommas(coverage / 12 || 0)}
          </p>
        </div>
      </div>
      <div className="md:grid md:grid-cols-8 md:gap-4 md:mt-4">
        <div className="text-xs mt-8 border-b-2 border-black xl:text-xl md:mt-0 md:col-span-5">
          <div className="bg-[#012060] text-white text-left p-2 sm:text-base font-bold">
            <p>Quote Prepared For</p>
          </div>
          <div className="px-2 py-1 flex bg-[#f2f2f2] items-center">
            <p className="w-1/3 text-left font-bold">Insured Name</p>
            <p className="w-2/3 text-right">{insuranceData.companyName}</p>
          </div>
          <div className="px-2 py-1 flex items-center">
            <p className="w-1/5 text-left font-bold">Address</p>
            <div className="w-4/5 text-right flex flex-col items-end sm:flex-row sm:justify-end">
              {address.map((text) => (
                <p key={text} className="w-fit">
                  {text}
                </p>
              ))}
            </div>
          </div>
          <div className="px-2 py-1 flex bg-[#f2f2f2]">
            <p className="w-2/3 text-left font-bold">Fleet Size</p>
            <p className="w-1/3 text-right">{insuranceData.trucks}</p>
          </div>
          <div className="px-2 py-1 flex">
            <p className="w-2/3 text-left font-bold">Number of Drivers</p>
            <p className="w-1/3 text-right">{insuranceData.drivers}</p>
          </div>
        </div>
        <div className="text-xs mt-4 border-b-2 border-black xl:text-xl md:mt-0 md:min-h-max md:flex md:flex-col md:justify-between md:col-span-3">
          <div className="bg-[#012060] text-white text-left p-2 sm:text-base font-bold flex justify-between">
            <p className="w-fit">Coverages</p>
            <p className="w-fit">Coverage Limits</p>
          </div>
          <div className="px-2 py-1 flex bg-[#f2f2f2]">
            <p className="w-1/2 text-left font-bold">Auto Liability</p>
            <p className="w-1/2 text-right">$1,000,000</p>
          </div>
          <div className="px-2 py-1 flex">
            <p className="w-1/2 text-left font-bold">Physical Damage</p>
            <p className="w-1/2 text-right">Included</p>
          </div>
          <div className="px-2 py-1 flex bg-[#f2f2f2]">
            <p className="w-1/2 text-left font-bold">Motor Truck Cargo</p>
            <p className="w-1/2 text-right">$100,000</p>
          </div>
          <div className="px-2 py-1 flex">
            <p className="w-1/2 text-left font-bold">General Liability</p>
            <p className="w-1/2 text-right">$1,000,000</p>
          </div>
        </div>
      </div>
      <div className="flex mt-4 bg-[#dae3f3] font-bold">
        <img
          src={producerImage}
          alt="CoverWhale logo"
          className="w-1/5 xl:w-1/6"
        />
        <div className="w-4/5 flex flex-col justify-between items-center py-2 px-1 sm:justify-around">
          <div className="italic text-[7px] sm:text-[10px] md:text-base xl:text-xl">
            <p>
              “Hey there! This is Stephen Langford at American Fleet - America’s
              #1 Fleet Insurance Broker. I‘d love the opportunity to earn your
              business. Please contact me today with any questions or to get
              started.”
            </p>
          </div>
          <p className="text-xs md:text-xl xl:text-3xl">
            (817) 242 5008 | Stephen@TeamAFI.com
          </p>
        </div>
      </div>
      <div className="flex flex-col items-center my-2 text-[#77727d] text-[6px] sm:text-[8px] italic md:text-xs md:w-11/12 md:mt-8">
        <p>
          Your actual premium may be higher or lower based on a number of
          factors. Coverages and discounts are subject to policy terms,
          conditions, qualifications, and availability. This proposal does not
          contain all possible coverage options and is only intended to
          highlight some of your coverage options. Please contact American Fleet
          to further discuss all of the additional coverage options which may be
          available.
        </p>
      </div>
      {location && (
        <button
          type="button"
          onClick={() =>
            navigate("/drivers", {
              state: {
                dotNumber: insuranceData.dotNumber,
                drivers: insuranceData.drivers,
                location: location
              }
            })
          }
          className="flex justify-center items-center font-bold text-white bg-green-700 rounded-lg py-2 px-8 sm:w-3/4 my-4"
        >
          <p className="w-fit sm:text-xl">Finalize Quote</p>
          <img
            src={arrowRight}
            alt="right arrow"
            className="w-6 lg:w-8 mt-0.5 lg:mt-1 ml-2"
          />
        </button>
      )}
    </div>
  );
};

export default CommercialTruck;
