import { useState } from "react";
import { Steps, useSteps } from "react-step-builder";
import ComercialTruck from "../ComercialTruck/ComercialTruck";
import { getTotalAxios, sendDotAxios, step1Axios, step2Axios, step3Axios, step4Axios, step5Axios } from "../../api/axiosFunctions";
import AddVehiclesModal from "../modals/AddVehiclesModal";
import Circles from "./Circles";
import usStates from '../../data/us-states-with-quotes.json';
import './MultiStepsForm.css';

const CostumerMultiStepsForm = () => {
  const { next, prev, current, total } = useSteps();
  let today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0');
  const yyyy = today.getFullYear();
  today = yyyy + '-' + mm + '-' + dd;
  const [comercialForm, setComercialForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState(1);
  const [dotChecked, setDotChecked] = useState(false);
  const [invalidState, setInvalidState] = useState(false);
  const [invalidUser, setInvalidUser] = useState(false);
  const [invalidCoverage, setInvalidCoverage] = useState(false);
  const [invalidOwnVehicles, setInvalidOwnVehicles] = useState(false);
  const [invalidNumberVehicles, setInvalidNumberVehicles] = useState(0);
  const [invalidPremium, setInvalidPremium] = useState(false);
  const [invalidStep2, setInvalidStep2] = useState(false);
  const [premium, setPremium] = useState(0);
  const [modalVehicle,setModalVehicle] = useState('modal_inactive');
  const [insuranceForm, setInsuranceForm] = useState({
    dotNumber: '',
    businessYears: '',
    coverageStartDate: today,
    companyName: '',
    garageState: 'selectState',
    garageZip: '',
    trucks: '',
    drivers: '',
    userName: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    radiusDriving: '0',
    opsTruck: 'opsLocal'
  });
  const [coverage, setCoverage] = useState({
    al: true,
    ntl: false,
    pd: true,
    mtc: true,
    gl: true
  });
  const [vehiclesArray, setVehiclesArray] = useState([]);

  const removeVehicleStatus = (index) => {
    let newArr = [...vehiclesArray];
    newArr[index].remove = true; 
    setVehiclesArray(newArr);
  };

  const acceptVehicleStatus = (index) => {
    let newArr = [...vehiclesArray];
    newArr[index].remove = false; 
    setVehiclesArray(newArr);
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInsuranceForm((prevState)=> {
      return({
        ...prevState,
        [inputName]: newValue,
      });
    });
  };

  const handleCoverageChange = (event) => {
    const inputName = event.target.name;
    setCoverage((prevState)=> {
      return({
        ...prevState,
        [inputName]: !coverage[inputName],
      });
    });
  };

  const handleStep1Submit = async (event) => {
    event.preventDefault();
    if (dotChecked || ['0', ''].includes(insuranceForm.dotNumber)) {
      const dotNumber = Math.random().toString().slice(2,10);
      next();
      setInsuranceForm((prevState)=> {
        return({
          ...prevState,
          "dotNumber": dotNumber,
        });
      });
      await step1Axios(dotNumber, insuranceForm.businessYears, insuranceForm.coverageStartDate, insuranceForm.opsTruck);
      return;
    };
    const response = await sendDotAxios(insuranceForm.dotNumber);
    if (response.data.error || Object.keys(response.data).length === 0) {
      next();
      return;
    };
    setInsuranceForm((prevState)=> {
      return({
        ...prevState,
        "garageState": response.data.generalDot?.phyState || 'State Not Found',
        "garageZip": response.data.generalDot?.phyZip || 'ZIP State Not Found',
        "drivers": response.data.generalDot?.totalDrivers || '0',
        "trucks": response.data.generalDot?.powerUnits || '0',
        "companyName": response.data.generalDot?.legalName || 'Company Not Found',
        "city": response.data.generalDot?.phyCity || 'City Not Found',
        "address": response.data.generalDot?.phyStreet || 'Address Not Found',
        "premiums": response.data.premiums
      });
    });
    const step1Result = await step1Axios(insuranceForm.dotNumber, insuranceForm.businessYears, insuranceForm.coverageStartDate, insuranceForm.opsTruck);
    if (step1Result.data.vehicles?.length > 0) {
      setVehiclesArray(step1Result.data.vehicles);
    } else {
      const vehiclesArray = [];
      response.data?.inspection.forEach((vehicle) =>
        vehiclesArray.push({
          vin: vehicle.vin,
          year: vehicle.ModelYear,
          make: vehicle.Make,
          model: vehicle.Model,
          value: "30000",
          classKey: "8",
          bodyTypeKey: "tractor",
          deductibleType: "FollowsPolicy",
          deductible: "1000"
        })
      );
      setVehiclesArray(vehiclesArray);
    };
    next();
  };

  const handleStep2Submit = async (event) => {
    event.preventDefault();
    setInvalidOwnVehicles(false);
    setInvalidState(false);
    setInvalidStep2(false);
    setInvalidNumberVehicles(0);
    if (insuranceForm.garageState === 'selectState') {
      setInvalidState(true);
      return;
    };
    if ([insuranceForm.companyName, insuranceForm.garageZip, insuranceForm.trucks, insuranceForm.drivers].some((element) => element === '')) {
      setInvalidStep2(true);
      return;
    };
    await step2Axios(insuranceForm.dotNumber, insuranceForm.companyName, insuranceForm.garageState, insuranceForm.garageZip, insuranceForm.trucks, insuranceForm.drivers)
    next();
  };

  const handleStep3Submit = async (event) => {
    event.preventDefault();
    setInvalidOwnVehicles(false);
    setInvalidNumberVehicles(0);
    const ownVehicles = [...vehiclesArray].filter((vehicle) => !vehicle.remove);
    if (ownVehicles.length === 0) {
      setInvalidOwnVehicles(true);
      return;
    };
    if (ownVehicles.length !== +insuranceForm.trucks) {
      setInvalidNumberVehicles(+insuranceForm.trucks - ownVehicles.length);
      return;
    };
    await step3Axios(insuranceForm.dotNumber, ownVehicles);
    next();
  };

  const handleStep4Submit = async(event) => {
    event.preventDefault();
    setInvalidCoverage(false);
    if (Object.values(coverage).every(item => !item)) {
      setInvalidCoverage(true);
      return;
    };
    await step4Axios(insuranceForm.dotNumber, insuranceForm.radiusDriving, coverage);
    next();
  };

  const handleFinalSubmit = async (event) => {
    event.preventDefault();
    setInvalidUser(false);
    setInvalidPremium(false);
    if (insuranceForm.userName === '' || insuranceForm.email === '' || insuranceForm.phone === '') {
      setInvalidUser(true);
      return;
    };
    setLoading(true);
    setLoadingText(1);
    setTimeout(() => {setLoadingText(2)},2000);
    setTimeout(() => {setLoadingText(3)},5000);
    step5Axios(insuranceForm.dotNumber, insuranceForm.email, insuranceForm.phone, insuranceForm.userName);
    const ownVehicles = [...vehiclesArray].filter((vehicle) => !vehicle.remove);
    const finalResponse = await getTotalAxios(insuranceForm, ownVehicles, coverage);
    if (finalResponse.data?.totalpremium > 0) {
      setPremium(finalResponse.data.totalpremium);
      setComercialForm(true);
    } else {
      setInvalidPremium(true);
    };
    setLoading(false);
  };

  return (
    <div className="multiStepsFormPage">
      { comercialForm ?
        <ComercialTruck insuranceData={insuranceForm} coverage={premium} />
        :
        <>
          { current > 1 && <h2 className="lg:text-3xl text-xl">RAPID QUOTE</h2>}
          { current === 1 &&
            <div className="w-11/12 flex flex-col items-center mb-4 sm:mb-0">
              <p className="font-bold my-3 lg:text-3xl text-xl">Welcome to American Fleet's Rapid Quote.</p>
              <div
                className="relative w-5/6 flex justify-center mt-auto pt-[55%] sm:pt-[35%] lg:pt-[20%] sm:w-1/2 mb:w-1/3"
              >
                <iframe
                  src="https://player.vimeo.com/video/803142341?h=afe1b491df&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  allow="autoplay; picture-in-picture"
                  allowFullScreen={true}
                  className="absolute top-0 left-0 w-[100%] h-[100%] lg:left-auto"
                  title="Rapid Quote"
                >
                </iframe>
              </div>
            </div>
          }
          <div className="circlesDiv">
            <Circles circleNumber={total} current={current} />
          </div>
          <Steps>
            <form onSubmit={handleStep1Submit} className="min-h-[370px] flex flex-col justify-between lg:w-3/5 lg:min-h-0">
              <label className="CostumerMultiStepsLabel">
                <h4 className="w-fit pl-4 text-base lg:text-xl">DOT Number</h4>
                {!dotChecked && <input name="dotNumber" type="number" pattern="\d*" className="multistepsinput" id="dotNumberInput" onChange={handleChange} value={insuranceForm.dotNumber} />}
                <div className="flex items-center pl-4 text-base lg:text-xl">
                  <input type="checkbox" className="h-4 w-4 mr-4 rounded border-gray-300 text-red-600 focus:ring-red-500" checked={dotChecked} onChange={() => setDotChecked(!dotChecked)} />
                  I do not have a DOT Number yet
                </div>
              </label>
              <label className="CostumerMultiStepsLabel">
                <h4 className="w-fit pl-4 text-xl">Business Type</h4>
                <select name="opsTruck" className="multiStepsselect" onChange={handleChange} value={insuranceForm.opsTruck}>
                  <option value="opsLocal">Local Trucker</option>
                  <option value="opsLongHaul">Long Haul Trucker</option>
                  <option value="opsHotShot">Hot Shot</option>
                  <option value="opsDumpTruckSandGravel">Dump Truck (Sand and Gravel)</option>
                  <option value="opsTowing">Towing</option>
                  <option value="opsOther">Other</option>
                </select>
              </label>
              <label className="CostumerMultiStepsLabel">
                <h4 className="w-fit pl-4 text-base lg:text-xl">Number of Years in Business</h4>
                <input name="businessYears" type="number" pattern="\d*" className="multistepsinput" onChange={handleChange} value={insuranceForm.businessYears} />
              </label>
              <label className="CostumerMultiStepsLabel mb-auto">
                <h4 className="w-fit pl-4 text-left text-base lg:text-xl">When would you like your coverage to start?</h4>
                <input name="coverageStartDate" type="date" className="multistepsinput appearance-none" onChange={handleChange} value={insuranceForm.coverageStartDate} />
              </label>
              <button type="submit" className="flex justify-center items-center font-bold text-white bg-blue-900 rounded-xl py-2 px-8 my-2 lg:mt-4">
                <p className="w-fit lg:text-xl">Continue</p>
                <p className="text-2xl w-fit ml-1 lg:text-3xl">&#8594;</p>
              </button>
            </form>
            <form onSubmit={handleStep2Submit}  className="lg:grid lg:grid-cols-2 lg:gap-x-12 min-h-[55vh] lg:w-3/5 lg:min-h-[400px]">
              <h3 className="lg:text-xl lg:col-span-2">Please confirm, edit or complete the below information:</h3>
              <label className="CostumerMultiStepsLabel lg:col-span-2">
                <h4 className="w-fit pl-4 text-xl">Company Name</h4>
                <input name="companyName" type="text" className="multistepsinput" onChange={handleChange} value={insuranceForm.companyName} />
              </label>
              <label className="CostumerMultiStepsLabel">
                <h4 className="w-fit pl-4 text-xl">Garage State</h4>
                <select name="garageState" className="multiStepsselect" onChange={handleChange} value={insuranceForm.garageState}>
                  <option value="selectState">Select State</option>
                  {usStates.map((state) => (
                    <option key={state.id} value={state.value_back}>{state.value}</option>
                  ))}
                </select>
                {invalidState && <p className="text-red-700 text-left text-sm pl-4 -mt-2">Please select a State</p>}
              </label>
              <label className="CostumerMultiStepsLabel">
                <h4 className="w-fit pl-4 text-xl">Garage Zip Code</h4>
                <input name="garageZip" type="number" pattern="\d*" className="multistepsinput" onChange={handleChange} value={insuranceForm.garageZip} />
              </label>
              <label className="CostumerMultiStepsLabel">
                <h4 className="w-fit pl-4 text-xl">Number of Trucks</h4>
                <input name="trucks" type="number" pattern="\d*" className="multistepsinput" onChange={handleChange} value={insuranceForm.trucks} />
              </label>
              <label className="CostumerMultiStepsLabel mb-4">
                <h4 className="w-fit pl-4 text-xl">Number of Drivers</h4>
                <input name="drivers" type="number" pattern="\d*" className="multistepsinput" onChange={handleChange} value={insuranceForm.drivers} />
              </label>
              {invalidStep2 && <p className="text-red-700 text-sm font-bold mt-2 mb-4 lg:col-span-2 lg:text-lg">Please Complete All Fields</p>}
              <div className="flex items-center justify-around lg:justify-between my-auto lg:col-span-2">
                <button type="button" className="flex justify-center items-center font-bold text-white bg-red-700 rounded-xl h-12 w-2/5 px-8" onClick={prev}>
                  <p className="text-2xl w-fit mr-1 lg:text-3xl">&#8592;</p>
                  <p className="w-fit lg:text-xl">Previous</p>
                </button>
                <button type="submit" className="flex justify-center items-center font-bold text-white bg-blue-900 rounded-xl h-12 w-2/5 px-8">
                  <p className="w-fit lg:text-xl">Continue</p>
                  <p className="text-2xl w-fit ml-1 lg:text-3xl">&#8594;</p>
                </button>
              </div>
            </form>
            <form onSubmit={handleStep3Submit}  className="flex flex-col items-center min-h-[75vh] sm:w-4/5 lg:w-3/5">
              {vehiclesArray.length > 0 ?
                <div>
                  <h3 className="lg:text-xl text-xs mt-2">We found a few vehicles associated with your DOT number.</h3>
                  <h3 className="lg:text-xl text-xs mb-2">Please verify below the vehicles that you would like to insure.</h3>
                </div>
                :
                <div>
                  <h3 className="lg:text-xl text-xs mt-2">We haven’t found any vehicles associated with your DOT number.</h3>
                  <h3 className="lg:text-xl text-xs mb-2">Please add the vehicles you would like to have ensured.</h3>
                </div>
              }
              <div className="max-h-[50vh] min-h-[30vh] overflow-y-scroll flex flex-col rounded vehicles_table">
                {vehiclesArray.length > 0 &&
                  vehiclesArray.map((vehicle, index) => (
                  <div className="flex px-4 pt-2 lg:px-8" key={vehicle.id + vehicle.vin || vehicle._id + vehicle.vin}>
                    <div>
                      <h4 className="text-left font-bold text-md lg:text-3xl">{vehicle.year} {vehicle.model ? vehicle.model : 'Model not found'}</h4>
                      <h5 className="text-left text-sm lg:text-xl">VIN: {vehicle.vin}</h5>
                      <h6 className={`text-left text-sm font-bold lg:text-lg ${vehicle.remove ? "text-red-700" : "invisible"}`}>Not Included</h6>
                    </div>
                    <div className="flex justify-evenly items-start w-fit gap-x-2 text-xl lg:text-3xl">
                      <button
                        type="button"
                        className={`pb-2 pt-1 rounded-xl w-[36px] lg:w-[64px] text-white font-bold ${vehicle.remove ? "bg-gray-500" : "bg-green-700"}`}
                        onClick={() => acceptVehicleStatus(index)}>
                        &#10003;
                      </button>
                      <button
                        type="button"
                        className={`pb-2 pt-1 rounded-xl w-[36px] lg:w-[64px] text-white font-bold ${vehicle.remove ? "bg-red-700" : "bg-gray-500"}`}
                        onClick={() => removeVehicleStatus(index)}>
                        x
                      </button>
                    </div>
                  </div>
                  ))
                }
              </div>
              {invalidOwnVehicles && <p className="text-red-700 text-sm font-bold mt-2 -mb-4">Please Add at least one vehicle</p>}
              {invalidNumberVehicles !== 0 &&
                <div className="fixed top-0 w-full h-full z-10 flex justify-center items-center bg-slate-900 bg-opacity-95">
                  {invalidNumberVehicles > 0 ?
                    <div className="flex flex-col items-center lg:w-3/5 lg:gap-y-6">
                      <p className="text-xl text-white font-bold mt-2 mb-4 w-11/12">The number of trucks you have entered previously is {insuranceForm.trucks}, there {invalidNumberVehicles > 1 ? `are ${invalidNumberVehicles} trucks` : `is ${invalidNumberVehicles} truck`} missing from this list. If you wish to continue with only 1 truck, click Continue again.</p>
                      <div className="flex items-center justify-around my-auto lg:col-span-2">
                        <button type="button" className="flex justify-center items-center font-bold text-white bg-red-700 rounded-xl h-12 w-2/5 px-8" onClick={() => setInvalidNumberVehicles(0)}>
                          <p className="text-2xl w-fit mr-1 lg:text-3xl">&#8592;</p>
                          <p className="w-fit lg:text-xl">Return</p>
                        </button>
                        <button type="button" className="flex justify-center items-center font-bold text-white bg-blue-900 rounded-xl h-12 w-2/5 px-8" onClick={() => {setInvalidNumberVehicles(0); next()}}>
                          <p className="w-fit lg:text-xl">Continue</p>
                          <p className="text-2xl w-fit ml-1 lg:text-3xl">&#8594;</p>
                        </button>
                      </div>
                    </div>
                  :
                  <div className="flex flex-col items-center lg:w-3/5 lg:gap-y-6">
                    <p className="text-xl text-white font-bold mt-2 mb-4 w-11/12">The number of trucks you have entered previously is {insuranceForm.trucks}, there {Math.abs(invalidNumberVehicles) > 1 ? `are aditional ${Math.abs(invalidNumberVehicles)} trucks` : `is aditional ${Math.abs(invalidNumberVehicles)} truck`}{Math.abs(invalidNumberVehicles)} in this list. If you wish to continue with only 1 truck, click Continue again.</p>
                    <div className="flex items-center justify-around my-auto lg:col-span-2">
                      <button type="button" className="flex justify-center items-center font-bold text-white bg-red-700 rounded-xl h-12 w-2/5 px-8" onClick={() => setInvalidNumberVehicles(0)}>
                        <p className="text-2xl w-fit mr-1 lg:text-3xl">&#8592;</p>
                        <p className="w-fit lg:text-xl">Return</p>
                      </button>
                      <button type="button" className="flex justify-center items-center font-bold text-white bg-blue-900 rounded-xl h-12 w-2/5 px-8" onClick={() => {setInvalidNumberVehicles(0); next()}}>
                        <p className="w-fit lg:text-xl">Continue</p>
                        <p className="text-2xl w-fit ml-1 lg:text-3xl">&#8594;</p>
                      </button>
                    </div>
                  </div>
                  }
                </div>
              }
              <button
                type="button"
                onClick={() => setModalVehicle('modal_active')}
                className="rounded-3xl w-5/6 text-white font-bold py-2 px-8 mb-8 bg-green-700 mt-8 lg:text-xl lg:w-1/2">
                + Add Vehicle
              </button>
              <div className={modalVehicle}>
                <AddVehiclesModal setModalVehicle={setModalVehicle} vehiclesArray={vehiclesArray} setVehiclesArray={setVehiclesArray} />
              </div>
              <div className="flex items-center justify-around my-auto lg:col-span-2">
                <button type="button" className="flex justify-center items-center font-bold text-white bg-red-700 rounded-xl h-12 w-2/5 px-8" onClick={prev}>
                  <p className="text-2xl w-fit mr-1 lg:text-3xl">&#8592;</p>
                  <p className="w-fit lg:text-xl">Previous</p>
                </button>
                <button type="submit" className="flex justify-center items-center font-bold text-white bg-blue-900 rounded-xl h-12 w-2/5 px-8">
                  <p className="w-fit lg:text-xl">Continue</p>
                  <p className="text-2xl w-fit ml-1 lg:text-3xl">&#8594;</p>
                </button>
              </div>
            </form>
            <form onSubmit={handleStep4Submit} className="multiStepsForm items-center">
              <fieldset className="space-y-4 w-3/4 flex flex-col items-center">
                <h4 className="w-fit mt-2 text-xl font-bold">Radius of driving</h4>
                <legend className="sr-only">Radius of driving</legend>
                <div className="relative flex items-center">
                  <div className="flex h-5 items-center w-1/6">
                    <input
                      id="0miles"
                      aria-describedby="0-50 miles"
                      name="radiusDriving"
                      type="checkbox"
                      checked={insuranceForm.radiusDriving === "0"}
                      onChange={handleChange}
                      value="0"
                      className="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-500"
                    />
                  </div>
                  <div className="ml-3 text-xl text-left">
                    <label htmlFor="0-50 miles" className="font-medium text-gray-700">
                      0 - 50 Miles
                    </label>
                  </div>
                </div>
                <div className="relative flex items-center">
                  <div className="flex h-5 items-center w-1/6">
                    <input
                      id="51miles"
                      aria-describedby="51 - 200 Miles"
                      name="radiusDriving"
                      type="checkbox"
                      checked={insuranceForm.radiusDriving === "51"}
                      onChange={handleChange}
                      value="51"
                      className="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-500"
                    />
                  </div>
                  <div className="ml-3 text-xl text-left">
                    <label htmlFor="51 - 200 Miles" className="font-medium text-gray-700">
                      51 - 200 Miles
                    </label>
                  </div>
                </div>
                <div className="relative flex items-center">
                  <div className="flex h-5 items-center w-1/6">
                    <input
                      id="201miles"
                      aria-describedby="201 - 500 Miles"
                      name="radiusDriving"
                      type="checkbox"
                      checked={insuranceForm.radiusDriving === "201"}
                      onChange={handleChange}
                      value="201"
                      className="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-500"
                    />
                  </div>
                  <div className="ml-3 text-xl text-left">
                    <label htmlFor="201 - 500 Miles" className="font-medium text-gray-700">
                      201 - 500 Miles
                    </label>
                  </div>
                </div>
                <div className="relative flex items-center">
                  <div className="flex h-5 items-center w-1/6">
                    <input
                      id="501miles"
                      aria-describedby="01+ Miles"
                      name="radiusDriving"
                      type="checkbox"
                      checked={insuranceForm.radiusDriving === "501"}
                      onChange={handleChange}
                      value="501"
                      className="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-500"
                    />
                  </div>
                  <div className="ml-3 text-xl text-left">
                    <label htmlFor="01+ Miles" className="font-medium text-gray-700">
                      501+ Miles
                    </label>
                  </div>
                </div>
              </fieldset>
              <fieldset className="space-y-4 mb-8 mt-4 w-3/4 flex flex-col items-center">
                <h4 className="w-fit text-xl font-bold">Select your coverage(s)</h4>
                <legend className="sr-only">Select your coverage(s)</legend>
                <div className="relative flex items-center">
                  <div className="flex h-5 items-center w-1/6">
                    <input
                      id="al"
                      aria-describedby="Automotive Liability"
                      name="al"
                      type="checkbox"
                      checked={coverage.al}
                      onChange={handleCoverageChange}
                      className="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-500"
                    />
                  </div>
                  <div className="ml-3 text-xl text-left">
                    <label htmlFor="Automotive Liability" className="font-medium text-gray-700">
                      Automotive Liability
                    </label>
                  </div>
                </div>
                <div className="relative flex items-center">
                  <div className="flex h-5 items-center w-1/6">
                    <input
                      id="ntl"
                      aria-describedby="Non-Trucking Liability"
                      name="ntl"
                      type="checkbox"
                      checked={coverage.ntl}
                      onChange={handleCoverageChange}
                      className="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-500"
                    />
                  </div>
                  <div className="ml-3 text-xl text-left">
                    <label htmlFor="Non-Trucking Liability" className="font-medium text-gray-700">
                      Non-Trucking Liability
                    </label>
                  </div>
                </div>
                <div className="relative flex items-center">
                  <div className="flex h-5 items-center w-1/6">
                    <input
                      id="pd"
                      aria-describedby="Physical Damage"
                      name="pd"
                      type="checkbox"
                      checked={coverage.pd}
                      onChange={handleCoverageChange}
                      className="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-500"
                    />
                  </div>
                  <div className="ml-3 text-xl text-left">
                    <label htmlFor="Physical Damage" className="font-medium text-gray-700">
                      Physical Damage
                    </label>
                  </div>
                </div>
                <div className="relative flex items-center">
                  <div className="flex h-5 items-center w-1/6">
                    <input
                      id="mtc"
                      aria-describedby="Motor Truck Cargo"
                      name="mtc"
                      type="checkbox"
                      checked={coverage.mtc}
                      onChange={handleCoverageChange}
                      className="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-500"
                    />
                  </div>
                  <div className="ml-3 text-xl text-left">
                    <label htmlFor="Motor Truck Cargo" className="font-medium text-gray-700">
                      Motor Truck Cargo
                    </label>
                  </div>
                </div>
                <div className="relative flex items-center">
                  <div className="flex h-5 items-center w-1/6">
                    <input
                      id="gl"
                      aria-describedby="General Liability"
                      name="gl"
                      type="checkbox"
                      checked={coverage.gl}
                      onChange={handleCoverageChange}
                      className="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-500"
                    />
                  </div>
                  <div className="ml-3 text-xl text-left">
                    <label htmlFor="General Liability" className="font-medium text-gray-700">
                      General Liability
                    </label>
                  </div>
                </div>
              </fieldset>
              {invalidCoverage && <p className="text-red-700 text-sm font-bold mb-2">Please Select at least one coverage</p>}
              <div className="flex items-center justify-around my-auto lg:col-span-2">
                <button type="button" className="flex justify-center items-center font-bold text-white bg-red-700 rounded-xl h-12 w-2/5 px-8" onClick={prev}>
                  <p className="text-2xl w-fit mr-1 lg:text-3xl">&#8592;</p>
                  <p className="w-fit lg:text-xl">Previous</p>
                </button>
                <button type="submit" className="flex justify-center items-center font-bold text-white bg-blue-900 rounded-xl h-12 w-2/5 px-8">
                  <p className="w-fit lg:text-xl">Continue</p>
                  <p className="text-2xl w-fit ml-1 lg:text-3xl">&#8594;</p>
                </button>
              </div>
            </form>
            <form onSubmit={handleFinalSubmit}  className="multiStepsForm">
              <p className="lg:text-3xl my-4 font-bold text-sm">Once you fill out your information below our technology will search A+ carriers and present to you a quote with the best coverage at the lowest cost.</p>
              <label className="CostumerMultiStepsLabel">
                <h4 className="w-fit pl-4 text-xl">Your Name</h4>
                <input name="userName" type="text" className="multistepsinput" onChange={handleChange} value={insuranceForm.userName} />
              </label>
              <label className="CostumerMultiStepsLabel">
                <div className="flex justify-start items-center">
                  <h4 className="w-fit pl-4 text-left text-xl">Email</h4>
                  <p className="text-sm w-fit ml-2 italic">(Where we will email your quote)</p>
                </div>
                <input name="email" type="email" className="multistepsinput" onChange={handleChange} value={insuranceForm.email} />
              </label>
              <label className="CostumerMultiStepsLabel">
                <div className="flex justify-start items-center">
                  <h4 className="w-fit pl-4 text-left text-xl">Cell Phone</h4>
                  <p className="text-sm w-fit ml-2 italic">(Where we will text your quote)</p>
                </div>
                <input name="phone" type="number" pattern="\d*" className="multistepsinput" onChange={handleChange} value={insuranceForm.phone} />
              </label>
              <div className="my-auto">
                {invalidUser && <p className="text-red-700 text-sm font-bold mb-2">Please complete all fields</p>}
                {invalidPremium && <p className="text-red-700 text-sm font-bold mb-2">There was a problem with your coverage</p>}
                <div className="flex items-center justify-around">
                  <button type="button" className="flex justify-center items-center font-bold text-white bg-red-700 rounded-xl h-12 w-2/5 px-8" onClick={prev}>
                    <p className="text-2xl w-fit mr-1 lg:text-3xl">&#8592;</p>
                    <p className="w-fit lg:text-xl">Previous</p>
                  </button>
                  <button type="submit" className="flex justify-center items-center font-bold text-white bg-blue-900 rounded-xl h-12 w-2/5 px-8">
                    <p className="w-fit lg:text-xl">Submit</p>
                    <p className="text-2xl w-fit ml-1 lg:text-3xl">&#8594;</p>
                  </button>
                </div>
              </div>
            </form>
          </Steps>
        </>
      }
      { loading && (
        <div className="loader">
          { loadingText === 1 && <p className="text-xl font-bold mb-6">Shopping A+ Carriers </p> }
          { loadingText === 2 && <p className="text-xl font-bold mb-6 w-9/12">Finding the best coverage for the lowest price </p> }
          { loadingText === 3 && <p className="text-xl font-bold mb-6">Preparing your quote </p> }
          <div className="spinner" />
        </div>
      )}
    </div>
  );
};

export default CostumerMultiStepsForm;
